import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import baseUrl from '../../baseUrl';
import IsLoading from '../../Components/isLoading/isLoading.js';
import withTimeoutWithoutRestriction from '../../Components/HOCs/withTimeoutWithoutRestriction.hoc';
import swal from 'sweetalert';
import LoginError from '../../Components/loginError/LoginError';
import LoginPageHeader from '../../Components/LoginPageHeader/loginPageHeader';
import LoginPageLayoutWrapper from '../../Components/LoginPageLayoutWrapper/loginPageLayoutWrapper';
import {ReactComponent as Left} from '../../assets/icon/left.svg';



const SecurityQuestionSet = () => {
    const [state, setState] = useState({
        loggingIn: false,
        loginError: false,
        securityQuestions:[],
        newQuestions:[],
        question1Id:'',
        question2Id:'',
        question3Id:'',
        answer1:'',
        answer2:'',
        answer3:''
    })
    const { agentId, auth_token } = JSON.parse(sessionStorage.getItem('userDetails'));
    const history = useHistory();

    useEffect(()=>{
        fetch(`${baseUrl}/oauth/all-questions`, {
            method: 'get',
            headers: {
              'Content-Type': 'application/json'
            },
          }).then(response => response.json())
            .then(questions => {
                let newArray = [];
                for(let i = 0; i<questions.respBody.length; i++){
                    newArray[i] = questions.respBody[i]
                    newArray[i].status = false
                    newArray[i].refId = ""
                }
              setState({
                  ...state,
                  securityQuestions: questions.respBody,
                  newQuestions: newArray
                })
            })
            .catch(err => {
              swal('Error', `${err}`, 'info')
            });
    }, [])
    // console.log(state.securityQuestions)

    const onChange =(e)=>{
        setState({
            ...state,
           [ e.target.name]: e.target.value
        })
    }

    const onSelectChange = (e) =>{
        const {newQuestions} = state
        // console.log(state.question1Id)
        const id = e.target[e.target.selectedIndex].id
      
        let tempArray = []
        for (let i = 0; i<newQuestions.length; i++){
          tempArray[i] = newQuestions[i]
          if(tempArray[i].refId == e.target.id){
             tempArray[i].status = false
             tempArray[i].refId = ""
          }
          if(id == tempArray[i].id){
            
            tempArray[i].status = !tempArray[i].status
            tempArray[i].refId = e.target.id
          }
        }
        // console.log('temp', tempArray)
      
        setState({
          ...state,
          newQuestions: tempArray,
          [e.target.name]: e.target.value,
          newId: id
        })
      
        // console.log(this.state.question1Id)
        e.target.options[0].selected = true
      
        // let optionId = newQuestions.find(question => question.id.match((id)))
        // console.log(optionId)
      
      }

    const setSecurityQuestions = (e) =>{
        e.preventDefault();
        setState(state=>({
            ...state,
            loggingIn: true
        }))

        let reqBody = {
            agentId,
            answer1,
            answer2,
            answer3,
            question1Id,
            question2Id,
            question3Id
        }

        if (answer1 === '' || answer2 === '' || answer3 === '' || question1Id === '' || question2Id=== '' || question3Id=== '' ){
            swal("Failed Attempt", "Please fill all fields", "error")
        }else{
            fetch (`${baseUrl}/oauth/save-questions`, {
                method: 'post',
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization': `Bearer ${auth_token}`
                }, 
                body: JSON.stringify(reqBody)
              }).then (response => response.json())
              .then(
                setupInformation => {
                if (setupInformation.respCode === "00") {
                  swal("Successful Operation", "Security Question set", "success")
                  history.push('/');
                } else {
                    swal("Unsuccessful Operation", "An error occured", "error")
                }
              })
              .catch(err => {
                this.setState({loggingIn: false})
                swal('An Error Occured', `${err}`, 'error')
            });
        } 

        // console.log(reqBody)
    }

    const goBack = (e) =>{
        e.preventDefault();
        const { userType } = JSON.parse(sessionStorage.getItem('userDetails'));
        // console.log(userType)
        if (userType.toLowerCase() === 'aggregator'){
            history.push('/aggregator')
        }
        else if (userType.toLowerCase() === 'sub agent' || userType.toLowerCase() === 'sub-agent' || userType.toLowerCase() === 'subagent' || userType.toLowerCase() === 'sole' || userType.toLowerCase() === 'sub_agent'){
            history.push("/dashboard");
        } 
        else if (userType.toLowerCase() === 'subaggregator' || userType.toLowerCase() === 'sub-aggregator'){
            history.push("/sub-aggregator");
        }
    }

    const {loginError, loggingIn, securityQuestions, newQuestions, answer1, answer2, answer3, question1Id, question2Id, question3Id} = state;
    return (
        
        <LoginPageLayoutWrapper>
        <div id="login-container">
          <LoginPageHeader text="Set security questions" />

          {/*-- Security question Form */}
          <div>
            <form onSubmit={setSecurityQuestions}>
                <div className="form-group mb-05" style={{display:'block'}}>
                    <label>Question 1</label>
                    <select 
                        className="form-control"
                        onChange = {onSelectChange}
                        name = "question1Id"
                        value={question1Id}
                        required
                        id="q1"
                    >

                    <option value={question1Id} >{ !question1Id ? 'Select first security question' : newQuestions[question1Id-1].name }</option>
                        
                            { 
                                newQuestions ?
                                newQuestions.filter((question)=>question.status === false).map((question, i)=>{
                                return  <option
                                        value={question.id}
                                        id={question.id}
                                        key={i}
                                    >
                                        {question.name}
                                    </option>
                                })
                                :
                                null
                            }
                    </select>
                </div> 

                <div className="form-group mb-05">
                    <input 
                        type="text" 
                        className="form-control" 
                        required="required"
                        placeholder="Answer 1"
                        name="answer1"
                        value={answer1}
                        onChange={onChange}
                    />
                </div>

                <div className="form-group mb-05" style={{display:'block'}}>
                    <label style={{marginTop: '15px'}}>Question 2</label>
                    <select 
                        className="form-control"
                        onChange = {onSelectChange}
                        name = "question2Id"
                        value={question2Id}
                        required
                        id="q2"
                    >

                    <option value={question2Id}>{ !question2Id ? 'Select second security question' : newQuestions[question2Id-1].name }</option>
                    {/* <option value="" selected={true}>Select third security question</option> */}
                        
                            { 
                                newQuestions ?
                                newQuestions.filter((question)=>question.status === false).map((question, i)=>{
                                return  <option
                                        value={question.id}
                                        id={question.id}
                                        key={i}
                                    >
                                        {question.name}
                                    </option>
                                })
                                :
                                null
                            }
                    </select>
                </div> 

                <div className="form-group mb-05">
                    <input 
                        type="text" 
                        className="form-control" 
                        required="required"
                        onChange={onChange}
                        placeholder="Answer 2"
                        name="answer2"
                        value={answer2}
                    />
                </div> 

                <div className="form-group mb-05" style={{display:'block'}}>
                    <label style={{marginTop: '15px'}}>Question 3</label>
                    <select 
                        className="form-control"
                        onChange = {onSelectChange}
                        name = "question3Id"
                        value={question3Id}
                        required
                        id="q3"
                    >

                    <option value={question3Id}>{ !question3Id ? 'Select third security question' : newQuestions[question3Id-1].name }</option>
                    {/* <option value="" selected={true}>{ !question3Id ? 'Select third security question' : newQuestions[question3Id-1].name }</option> */}
                        
                            { 
                                newQuestions ?
                                newQuestions.filter((question)=>question.status === false).map((question, i)=>{
                                return  <option
                                        value={question.id}
                                        key={i}
                                        id={question.id}
                                    >
                                        {question.name}
                                    </option>
                                })
                                :
                                null
                            }
                    </select>
                </div> 

                <div className="form-group mb-05">
                    <input 
                        type="text" 
                        className="form-control" 
                        required="required"
                        onChange={onChange}
                        placeholder="Answer 3"
                        name="answer3"
                        value={answer3}
                    />
                </div> 
              <br/>

              <div className="form-group">
                <button className="form-back" style={{marginRight:'10px'}} onClick={goBack}>
                    <Left className="right-five" />  back
                </button>
                <button 
                  type="submit" 
                  className="btn btn-danger"                      
                  >
                  {
                    loggingIn ? <IsLoading />
                    : 'Proceed'
                  }
                </button>
              </div>
            </form>
              {
                loginError ? <LoginError /> : null
              }
          </div>
        </div>
      </LoginPageLayoutWrapper>
    )
}

export default withTimeoutWithoutRestriction(withRouter(SecurityQuestionSet));
