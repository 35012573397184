import React, { Fragment, useState, createContext} from 'react';
import '../AgentOnboarding/AgentCreation.scss'
import PinSetup from './PinSetup.jsx';
import PasswordSetup from './PasswordSetup.jsx';
import SetQuestions from './SetQuestions.jsx';

export const AgentSetupContext = createContext();
const AgentSetup = ({ onChange, onSelectChange, manipulateNumber, onReturnLogin, AgentSetupButton, state:{securityQuestions, newId, loggingIn, loginError, newPassword, newPasswordAgain, newPin, newPinAgain, newQuestions, answer1, answer2, answer3, question1Id, question2Id, question3Id} }) => {
  const [state, setState] = useState({
    step:1
  })

  const onClickNext = (e) =>{
    e.preventDefault();
    let currentStep = state.step;
    currentStep = currentStep >= 2 ? 3 : currentStep + 1
    setState(state=>({
        ...state,
        step: currentStep
    }))

}

const onClickPrev = (e) =>{
    e.preventDefault();
    let currentStep = state.step;
    currentStep = currentStep <=1 ? 1 : currentStep - 1
    setState(state=>({
        ...state,
        step: currentStep
    }))

}

  const renderPages = () =>{
    switch(state.step){
      case 1:
          return <PasswordSetup />;
      case 2:
          return <PinSetup />;
      case 3:
          return <SetQuestions />;
      default:
          return <PasswordSetup />
  }
  }
  return (
    <Fragment>
      <AgentSetupContext.Provider value={{
          onChange,
          manipulateNumber, 
          onReturnLogin, 
          securityQuestions, 
          loggingIn, 
          loginError,
          AgentSetupButton,
          newPassword,
          newPasswordAgain,
          newPin,
          newPinAgain,
          newQuestions,
          onSelectChange,
          newId,
          answer1, 
          answer2, 
          answer3, 
          question1Id, 
          question2Id, 
          question3Id,
          onClickNext,
          onClickPrev
      }}>
        <div className="agent-creation">
          {renderPages()}
        </div>
      </AgentSetupContext.Provider>
    </Fragment>
	)
}
  
export default AgentSetup;