import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import IsLoading from '../../Components/isLoading/isLoading.js';
import LoginError from '../../Components/loginError/LoginError';
import LoginPageHeader from '../../Components/LoginPageHeader/loginPageHeader.jsx';
import LoginPageFooter from '../../Components/LoginPageFooter/loginPageFooter.jsx';
import PasswordResetLink from '../../Components/PasswordResetLink/passwordResetLink.jsx';
import SelfOnboard from '../../Components/selfOnboard/selfOnboard.jsx';

const Login = ({ onChange, loginButtonClick, loggingIn, loginError, loginDesc }) => {
	return (
    <Fragment>
      <div className="verify_agent_link">
        <Link to="/verify-agent"> Verify Agent </Link>
      </div>
      <div id="login-container">
        <LoginPageHeader text='Welcome! Please login to your account.' />

        <form onSubmit={loginButtonClick}>
          <div className="form-group">
            <div className="col-sm-12 col-md-8 col-lg-8">
              <input
                type="text" 
                className="form-control" 
                required="required" 
                autoFocus="autofocus" 
                placeholder="Username"
                name="username"
                autoComplete="autocomplete"
                onChange={onChange} 
              />
            </div>
          </div>

          <div className="form-group">
            <div className="col-sm-12 col-md-8 col-lg-8">          
              <input 
                type="password" 
                className="form-control" 
                required="required" 
                autoComplete="autocomplete" 
                placeholder="Password" 
                name= "password"
                onChange={onChange} />
            </div>
          </div>
        
          <div className="form-group">        
            <button 
              type="submit"
              className="btn btn-danger"
              disabled={loggingIn}
            >
              {
                loggingIn ? <IsLoading />
                : 'Login'
              }
            </button>
          </div>
        </form>

          <PasswordResetLink />
          <SelfOnboard />

          {
            loginError ? 
            <LoginError 
             loginDesc= {loginDesc}/> : null
          }
        <LoginPageFooter />
      </div>
    </Fragment>
	)
}

export default Login;