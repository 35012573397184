import React, {Fragment} from 'react';
import IsLoading from '../../Components/isLoading/isLoading.js';
import LoginError from '../../Components/loginError/LoginError';
import LoginPageHeader from '../../Components/LoginPageHeader/loginPageHeader.jsx';

const SecurityQuestions = ({ onChange, securityQuestionButton, loggingIn, loginError, questionName, loginDesc }) => {
    return (
        <Fragment>
        <div id="login-container">
            <LoginPageHeader text="Kindly enter the same answer supplied while setting security questions" />

            {/*-- Agent Setup Form */}
            <div>
                <form onSubmit={securityQuestionButton}>
                <div className="form-group">
                    <label>{questionName}</label>
                </div>
                <div className="form-group">
                    <div className="col-sm-12 col-md-8 col-lg-8">
                    <input 
                        type="password" 
                        className="form-control" 
                        required="required"
                        placeholder="Security question answer"
                        name="agentAnswer"
                        onChange={onChange} />
                    </div>
                </div>
                 <br/>

                <div className="form-group">
                    <button 
                    type="submit" 
                    className="btn btn-danger"
                    disabled={loggingIn}
                    >
                    {
                        loggingIn ? <IsLoading />
                        : 'Proceed'
                    }
                    </button>
                </div>
                </form>
                {
                    loginError ? <LoginError loginDesc={loginDesc} /> : null
                }
            </div>
        </div>
    </Fragment>
    )
}

export default SecurityQuestions
