import React from 'react';
import {ReactComponent as Left} from '../../assets/icon/left.svg';
import IsLoading from '../../Components/isLoading/isLoading';

const TermsConditions = (props) => {

    return (
        <div className="bvn-input">
            <h3 className="agent-sign"> <Left className="right-twenty cursor-pointer" onClick = {props.goBack} /> Terms and Conditions</h3>
            <h3 className='mb-1 permission' style={{color:'#EB0029'}}><strong>There have been some updates to our terms and conditions!!</strong></h3>
            {/* <div className="divider"></div> */}

            <br />
            <p className="content">
                {props.latestTerms} 
            </p>
            
            <div className="text-right" style={{marginTop:'15px'}}>
                <button className="transparent" style={{marginRight:'10px'}} onClick = {props.goBack}>
                    Cancel
                </button>
                <button className="form-continue" onClick={props.onAcceptTerms} style={{minWidth: '141.53px'}}>
                    {
                        props.submit ? <IsLoading />
                        :
                        'Accept Terms'
                    }
                </button>
            </div>

        </div>
    )
}

export default TermsConditions
