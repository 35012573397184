import React, {useState, createContext} from 'react';
import SecurityQuestion from './PasswordReset/SecurityQuestion';
import swal from 'sweetalert';
import baseUrl from '../../baseUrl';
import NewPasswordSetup from './PasswordReset/NewPasswordSetup';
// import { useHistory } from "react-router";

export const PasswordContext = createContext()
const PasswordReset = ({ onChange, routeChange, state:{newPassword, newPasswordAgain, questionName, agentAnswer, agentId, questionId, password, username} }) => {
    const auth_token = JSON.parse(sessionStorage.getItem('auth_token'));
    const [state, setState] = useState({
        step: 1,
        loginError: false,
        loginDesc: false,
        loggingIn: false
    })
    // const history = useHistory();

    const {loginDesc, step, loginError, loggingIn} = state;


    const confirmAnswer = (e) =>{
        e.preventDefault();

        setState(state=>({
            ...state,
            loggingIn: true
        }))

        if (agentAnswer === ''){
            swal("Failed Attempt", "Please fill all fields", "error")
        } 
        else {
            let reqBody = {
                agentAnswer,
                agentId,
                questionId
            };
            fetch(`${baseUrl}/oauth/verify-agent-answer`, {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(reqBody)
            })
            .then(response => response.json())
            .then(user => {
                setState(state=>({
                    ...state,
                    loggingIn: false
                }))
                if(user.respCode === '00'){
                    setState(state=>({
                        ...state,
                        step: 2
                    }))
                }
                else if(user.respCode === '96'){

                    setState(state=>({
                        ...state,
                        loginError: true, 
                        loginDesc: user.respDescription
                    }))
                }
            })
            .catch(err => {
                this.setState({loggingIn: false})
                swal('An Error Occured', `${err}`, 'info')
            })
        }
    }

    const onChangePassword = (e)=>{
         
        e.preventDefault();

        setState(state=>({
            ...state,
            loggingIn: true
        }))
        let reqBody = {
            oldPassword: password,
            newPassword: newPasswordAgain,
            agentUsername: username
        }
        fetch (`${baseUrl}/oauth/changepassword`, {
        method: 'post',
        headers: {
            'Content-Type' : 'application/json',
            'Authorization': `Bearer ${auth_token}`
        }, 
        body: JSON.stringify(reqBody)
        })
        .then (response => response.json())
        .then(result => {
            setState(state=>({
                ...state,
                loggingIn: false
            }))
            if (result.respCode === "00") {
                swal("Successful Operation", "New Password has been set. You will have to login again with this new password", "success");
                setState(state=>({
                    ...state,
                    loggingIn: false
                }))
                routeChange("")
            } 
            else if (result.respCode === "96") {
                swal("Ooops!!", `${result.respDescription}`, "error")
            } 
            else {
                swal('An Error Occured', 'There was an error while processing this request, please try again', 'error')
            }
        })
        .catch(err => {
            setState(state=>({
                ...state,
                loggingIn: false
            }))
            swal('An Error Occured', 'There was an error while processing this request, please try again', 'error')
        });
    }

    const renderPages = () =>{
        switch(step){
            case 1:
                return <SecurityQuestion />;
            case 2:
                return <NewPasswordSetup />;
            default:
                return <SecurityQuestion />
        }
    }

    return (
    <PasswordContext.Provider value={{
        questionName,
        onChange,
        confirmAnswer,
        newPassword,
        newPasswordAgain,
        loginDesc,
        loginError,
        loggingIn,
        onChangePassword
    }}>
        {renderPages()}
    </PasswordContext.Provider>
    )
}

export default PasswordReset;
