import React, {useContext} from 'react';
import {ReactComponent as Left} from '../../../assets/icon/left.svg';
import MakingPayment from '../../../Components/makingPayment/makingPayment';
import { AgentCreationContext } from '../AgentCreation';

const BankInfo = () => {
    const {onClickNext, onClickPrev, onChange, changeBank, state:{settlementAccountNo, settlementBank, accountName, validatedButton, disabled, nameValidation}} = useContext(AgentCreationContext);
    return (
        <div>
            <div>
                <h3 className="agent-sign">Settlement Bank Info</h3>
            </div>

            <div className="sub-head">
                <h4 className="agent-know">Bank Information <span style={{color:'#ff0014'}}>!</span></h4>
                <p className="agent-info" >Please input prefered Bank account details, This is where you will get your <strong>Income</strong> paid.</p>

            </div>

            <div>
                <form>
                    <div className="form-group">
                        <label>Select bank</label>
                        <div>
                            <select
                            type="text" 
                            required="required" 
                            className="form-control" 
                            name="settlementBank"
                            value={settlementBank}
                            onChange={changeBank}
                            readOnly={disabled}
                            >
                                <option value="" >Choose bank</option>
                                <option value="ABP">Access Bank Nigeria Plc</option>
                                <option value="CITY">Citibank Nigeria Limited</option>
                                <option value="DBP">Diamond Bank Plc</option>
                                <option value="ECO">Ecobank Nigeria</option>
                                <option value="ENT">Enterprise Bank</option>
                                <option value="FBP">Fidelity Bank Plc</option>
                                <option value="FBN">First Bank of Nigeria Plc</option>
                                <option value="FCMB">First City Monument Bank</option>
                                <option value="GTB">Guaranty Trust Bank Plc</option>
                                <option value="HBP">Heritage Bank</option>
                                <option value="KSB">Keystone Bank</option>
                                <option value="MSB">MainStreet Bank</option>
                                <option value="SKYE">Polaris Bank</option>
                                <option value="STANBIC">Stanbic IBTC Bank Plc</option>
                                <option value="SCB">Standard Chartered Bank</option>
                                <option value="SBP">Sterling Bank Plc</option>
                                <option value="UBN">Union Bank Nigeria Plc</option>
                                <option value="UBA">United Bank for Africa Plc</option>
                                <option value="UNITY">Unity Bank Plc</option>
                                <option value="WEMA">WEMA Bank Plc</option>
                                <option value="ZIB">Zenith Bank International</option>
                                <option value="PBL">Providus Bank</option>
                                <option value="JAIZ">Jaiz Bank</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Account Number</label>
                        <div>
                            <input
                            type="number" 
                            required="required" 
                            className="form-control" 
                            onChange={onChange}
                            value={settlementAccountNo}
                            name="settlementAccountNo"
                            disabled={disabled}
                            maxLength="11"
                            minLength="11"
                            />
                        </div>
                    </div>

                    {
                    accountName ? 
                    
                        <div className="form-group">
                            <input 
                                type="text" 
                                className="form-control" 
                                required="required" 
                                name="accountName"
                                value={accountName} 
                                readOnly
                            />
                        </div>
                        :
                        null
                    }

                    {
                        validatedButton ?
                        <div className="form-group">
                            <button className="form-continue full-width">
                               <MakingPayment />
                            </button>
                        </div>
                        :
                        null
                    }

                    <div style={{marginTop:'150px'}}>
                        <div className="row" style={{marginTop: '30px'}}>
                        <div className="col-lg-4" style={{paddingLeft:'0'}}>
                            <button className="form-back full-width"  onClick={onClickPrev}>
                            <Left className="right-five" />  back
                            </button>
                        </div>
                        <div className="col-lg-8" style={{paddingRight:'0', paddingLeft:'0'}}>
                            <button className="form-continue full-width" onClick={onClickNext} disabled={nameValidation}>
                               Continue 
                            </button>
                        </div>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
    )
}

export default BankInfo
