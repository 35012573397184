import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import baseUrl from '../../baseUrl';
import IsLoading from '../../Components/isLoading/isLoading.js';
import withTimeoutWithoutRestriction from '../../Components/HOCs/withTimeoutWithoutRestriction.hoc';
import { manipulateNumber } from '../../manipulateNumber';
import swal from 'sweetalert';
import LoginError from '../../Components/loginError/LoginError';
import LoginPageHeader from '../../Components/LoginPageHeader/loginPageHeader';
import LoginPageLayoutWrapper from '../../Components/LoginPageLayoutWrapper/loginPageLayoutWrapper';

class ChangePin extends Component {
  _isMounted = false;
    constructor(){
    super()
    this.state = {
      userDetails : {},
      changeRoute: false,
      loggingIn: false,
      loginError: false,
      oldPin: '',
      newPin: '',
      newPinAgain: '', 
      userType: ''
    }
  }

  onChange = (event) => { this.setState({[event.target.name]: event.target.value});}

  ChangePin = (e) => {
    e.preventDefault();
    this.setState({loginError: false})
    let reqBody = {
      agentUsername: this.state.userDetails.agentUsername,
      newPin: this.state.newPin,
      oldPin: this.state.oldPin
      };
    if (this.state.newPin === '' || this.state.newPinAgain === '' || this.state.oldPin === '' ){
      swal("Failed Attempt", "Please fill all fields", "info")
    } else if (this.state.newPin !== this.state.newPinAgain) {
      swal("Failed Attempt", "New Pins do not match", "error")
    } else if (this.state.newPin === this.state.newPinAgain) {
      ;
      this.setState({loggingIn: true})
      let auth_token = this.state.userDetails.auth_token;
      fetch (`${baseUrl}/oauth/changepin`, {
        method: 'post',
        headers: {
          'Content-Type' : 'application/json', 
          'Authorization': `Bearer ${auth_token}`
        }, 
        body: JSON.stringify(reqBody)
      }).then (response => response.json())
      .then(
        result => {
        if (result.respCode === "00") {
          swal("Successful Operation", "New Pin has been set.", "success");
          ;
          this.setState({loggingIn: false, changeRoute: true, userType: this.state.userDetails.userType.toLowerCase()})
          this.renderRedirect();
        } else if (result.respCode === "96") {
          this.setState({loggingIn: false, loginError: true})
          ;
        } else {
          swal('An Error Occured', 'There was an error while processing this request, please try again', 'error')
          this.setState({loggingIn: false, loginError: true})
          ;
        }
      })
      .catch(err => {
        this.setState({loggingIn: false})
        ;
        swal('An Error Occured', 'There was an error while processing this request, please try again', 'error')
      });
    }
}

  componentDidMount = async () => {
    this._isMounted = true;

    await sessionStorage.getItem('userDetails') && this.setState ({
      userDetails: JSON.parse(sessionStorage.getItem('userDetails'))
    }) 
  }

  renderRedirect = () => {
    const { changeRoute, userType } =  this.state;
    if (changeRoute && ( userType === 'aggregator' || userType === 'subaggregator')) {
      this.props.history.push("/aggregator");
      } else if (changeRoute && (userType === 'sub agent' || userType === 'sub-agent' || userType === 'subagent' || userType === 'sole' || userType === 'sub_agent')){
        this.props.history.push("/dashboard");
      } else {
        this.props.history.push("/");
      }
}

  render() {
    const { loginError, loggingIn } = this.state;

    return (
      <LoginPageLayoutWrapper>
        <div id="login-container">
          <LoginPageHeader text="Change Pin" />

          {/*-- Agent Setup Form */}
          <div>
            <form onSubmit={this.ChangePin}>
              <div className="form-group">
                <div className="col-sm-12 col-md-8 col-lg-8">
                  <input 
                    type="password" 
                    className="form-control" 
                    required="required"
                    placeholder="Enter Old Pin"
                    autoComplete="autocomplete"
                    autoFocus="autofocus"
                    maxLength="4"
                    name="oldPin"
                    onChange={this.onChange}
                    onKeyPress={(e) => manipulateNumber(e)}
                  />
                </div>
              </div> 
              <div className="form-group">
                <div className="col-sm-12 col-md-8 col-lg-8">          
                  <input 
                    type="password" 
                    className="form-control" 
                    required="required"
                    autoComplete="autocomplete" 
                    placeholder="Enter New Pin"
                    maxLength="4" 
                    name="newPin"
                    onChange={this.onChange}
                    onKeyPress={(e) => manipulateNumber(e)}
                  />
                </div>
              </div> 

              <div className="form-group">
                <div className="col-sm-12 col-md-8 col-lg-8">          
                  <input 
                    type="password" 
                    className="form-control" 
                    required="required"
                    autoComplete="autocomplete" 
                    placeholder="Confirm  New Pin"
                    maxLength="4"
                    name="newPinAgain" 
                    onChange={this.onChange}
                    onKeyPress={(e) => manipulateNumber(e)}
                  />
                </div>
              </div> <br/>

              <div className="form-group">
                <button 
                  type="submit" 
                  className="btn btn-danger"
                  onClick={this.ChangePin}>
                  {
                    loggingIn ? <IsLoading />
                    : 'Proceed'
                  }
                </button>
              </div>
            </form>
              {
                loginError ? <LoginError /> : null
              }
          </div>
        </div>
      </LoginPageLayoutWrapper>
    )
  }
	
}

export default withTimeoutWithoutRestriction(withRouter(ChangePin));