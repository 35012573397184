import React, { useState, useContext } from 'react';
import {ReactComponent as Left} from '../../../assets/icon/left.svg';
import Accordion from '../../../Components/UI/Accordion/Accordion';
import { AgentCreationContext } from '../AgentCreation';

const BvnInfo = () => {
    const {cancelFaq} = useContext(AgentCreationContext);

    const [state, setState] = useState({
        isFirstActive: true,
        isSecondActive: false,
        isThirdActive: false,
        isFourthActive: false
    })

    const showFirst = () =>{
        setState(state=>({
            ...state,
            isFirstActive: !isFirstActive
        }))
    }

    const showSecond = () =>{
        setState(state=>({
            ...state,
            isSecondActive: !isSecondActive
        }))
    }

    const showThird = () =>{
        setState(state=>({
            ...state,
            isThirdActive: !isThirdActive
        }))
    }

    const showFourth = () =>{
        setState(state=>({
            ...state,
            isFourthActive: !isFourthActive
        }))
    }

    const {isFirstActive, isFourthActive, isThirdActive, isSecondActive} = state;
    return (
        <div className="bvn-input">
            <h3 className="agent-sign"> <Left className="right-twenty cursor-pointer" onClick={cancelFaq}/> FAQs</h3>
            <h3 className='mb-1 permission' style={{color:'#EB0029'}}><strong>About BVN</strong></h3>

            <Accordion title="What is BVN?" clicked={showFirst} show={isFirstActive}>
                <p className="content">
                Bank Verification Number (BVN) is a biometric identification system implemented by the Central Bank of Nigeria given to each bank customer a unique identity across the Nigerian Banking industry that can be used for easy identification and verification.
                It is a unique 11-digit ID number issued to every Bank customer at enrolment and linked to every account that the customer has in all Nigerian Banks. Individuals shall be required to submit an acceptable means of identification as prescribed for enrolment.
                </p>
            </Accordion>
            <div className="divider"></div>
            <Accordion title="Why we ask for your BVN ?" clicked={showSecond} show={isSecondActive}>
                <p className="content">
                This information is only for identity confirmation purposes, and we do not share your details with any external parties. 3line is a licensed financial institution licensed by the Central Bank of Nigeria therefore your details are safe with us. The goal of the Bank Verification Number (BVN) is to uniquely verify the identity of each Bank’s customer for ‘know your customer’ (KYC) purposes.
                </p>
            </Accordion>
            <div className="divider"></div>
            <Accordion title="How secure is my data secure?" clicked={showThird} show={isThirdActive}>
                <p className="content">
                    Definitely! Your data is protected by the same bank-level security systems that protect the money processed through 3line, as we do not store your BVN details, we only verify with your bank. We are compliant with the National Data Protection  Regulation of the National Information Technology Development Agency (NITDA) for all data.
                </p>
            </Accordion>
            <div className="divider"></div>
            <Accordion title="I do not remember my BVN number" clicked={showFourth} show={isFourthActive}>
                <p className="content">
                    In case you have forgotten your BVN, simply dial *565*0# to retrieve it from the line you used when registering for the BVN.
                </p>
            </Accordion>

        </div>
    )
}

export default BvnInfo
