import React, {useContext} from 'react';
import {ReactComponent as Left} from '../../../assets/icon/left.svg';
import { AgentCreationContext } from '../AgentCreation';
import {ReactComponent as Add} from '../../../assets/icon/add-pic.svg';
import IsLoading from '../../../Components/isLoading/isLoading';

const Identification = () => {
    const {onClickPrev, onFileChange, onCheckReferral, termsConditions, createAgent, state:{picture, signature, terms, referralCheck, submit, upload}} = useContext(AgentCreationContext);
    let disabled = true;
    if(picture && signature && terms){
        disabled = false
    }
    return (
        <div>
            <div>
                <h3 className="agent-sign">Validate your Identity</h3>
            </div>

            <div className="sub-head">
                <h4 className="agent-sign">Almost there <span style={{color:'#ff0014'}}>!</span></h4>
                <p className="agent-info" >To Continue, Please input account profile image. This helps to Identify and secure your profile as our agent. Kindly upload in <strong> Jpeg, Jpg or Png</strong> formats only</p>

            </div>

            <form>
                    <div>
                        <input  
                            type="file" 
                            id="pic-btn" 
                            hidden  
                            accept=".png, .jpg, .jpeg" 
                            onChange={onFileChange}
                            name="picture"
                        />

                        <label htmlFor="pic-btn" className="display-block">
                            <div className="row cursor-pointer" >
                                <div className="col-lg-2 nopadding">
                                    {
                                        picture ?
                                        <img className="img-div" src={picture} alt="img" />
                                        :
                                        <div className="add-div">
                                            <Add className="add-icon" />
                                        </div>
                                    }
                                    

                                </div>

                                <div style={{marginTop:'14px'}}>
                                    <h4 className="agent-know">Input Profile Picture</h4>
                                    <p className="agent-warning">Click to input image, Please ensure you use a plain background.</p>
                                </div>

                            </div>
                        </label>
                    </div>

                    <div className="up-thirty">
                        <input  
                            type="file" 
                            id="sign-btn" 
                            accept=".png, .jpg, .jpeg" 
                            hidden
                            onChange={onFileChange}
                            name="signature"
                        />

                        <label htmlFor="sign-btn" className="display-block">
                            <div className="row cursor-pointer" >
                                <div className="col-lg-2 nopadding">
                                    {
                                        signature ?
                                        <img className="img-div" src={signature} alt="img" />
                                        :
                                        <div className="add-div">
                                            <Add className="add-icon" />
                                        </div>
                                    }
                                    

                                </div>

                                <div style={{marginTop:'14px'}}>
                                    <h4 className="agent-know">Signature</h4>
                                </div>

                            </div>
                        </label>
                    </div>

                {/* <div className="row cursor-pointer up-thirty">
                    <div className="col-lg-2 nopadding">
                        <div className="add-div">
                            <Add className="add-icon" />
                        </div>
                    </div>

                    <div style={{marginTop:'14px'}}>
                        <h4 className="agent-know">Signature</h4>
                    </div>
                </div> */}

                {
                    upload ?
                    <div className="form-group">
                        <button className="form-continue full-width">
                            Uploading Image......
                             {/* <MakingPayment /> */}
                        </button>
                    </div>
                    :
                    null
                }

                <div className="d-flex align-items-center mb-6">
                    <label className="checkbox checkbox-lg checkbox-primary flex-shrink-0 m-0 mr-4 ml-04">
                    <input type="checkbox" checked={terms} onChange={termsConditions} />
                    <span></span>  
                    </label>

                    <div className="d-flex flex-column flex-grow-1 py-2 mt-15">
                        <h3 className='mb-1 permission'>I agree to the terms and conditions</h3>
                        <h3 className='mb-1 permission cursor-pointer' style={{color:'#EB0029'}}><strong>View Terms and Conditions</strong></h3>
                    </div>
                </div>

                <div className="d-flex align-items-center mb-6">
                    <label className="checkbox checkbox-lg checkbox-primary flex-shrink-0 m-0 mr-4 ml-04">
                    <input type="checkbox" checked={referralCheck} onChange={onCheckReferral} />
                    <span></span>  
                    </label>

                    <div className="d-flex flex-column flex-grow-1 py-2 mt-15">
                        <h3 className='mb-1 permission'><strong>If referred by an agent, click here</strong></h3>
                        <h3 className='mb-1 permission'>If not, leave blank</h3>
                    </div>
                </div>

                
                    <div className="row" style={{marginTop: '30px'}}>
                    <div className="col-lg-4" style={{paddingLeft:'0'}}>
                            <button className="form-back full-width"  onClick={onClickPrev}>
                            <Left className="right-five" />  back
                            </button>
                        </div>
                        <div className="col-lg-8" style={{paddingRight:'0', paddingLeft:'0'}}>
                            <button className="form-continue full-width" onClick={createAgent} disabled={disabled}>
                                {
                                    submit ? <IsLoading />
                                    :
                                    'Create account'
                                }
                            </button>
                        </div>
                    </div>

            </form>
            
        </div>
    )
}

export default Identification
