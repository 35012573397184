import React, {useContext} from 'react';
import {ReactComponent as Left} from '../../assets/icon/left.svg';
import { AgentSetupContext } from './AgentSetup';

const PinSetup = () => {
    const {onChange, manipulateNumber, onClickPrev, onClickNext, newPin, newPinAgain} = useContext(AgentSetupContext)
    let disabled = true;
    let error = null

    if(newPin !== '' && newPinAgain !== ''){
        if(newPin !== newPinAgain){
            error = (<p className="agent-info" style={{color:'#ff0014'}} >Pins don't match!!!</p>)
            disabled=true
        }
        else{
            disabled = false
        }
    }
    return (
        <div>
            <div>
                <h3 className="agent-sign">Set PIN</h3>
            </div>

            <div className="sub-head">
                <h4 className="agent-know">We care about Security <span style={{color:'#ff0014'}}>!!!</span></h4>
                <p className="agent-info" >Kindly setup your 4 digit pin to be used for transactions.</p>

            </div>

            <div>
                <form>
                    
                    <div className="form-group">
                        <label>Input PIN</label>
                        <div>
                            <input
                             type="password" 
                             className="form-control" 
                             required="required"
                             autoComplete="autocomplete" 
                             maxLength="4" 
                             name="newPin"
                             onChange={onChange}
                             onKeyPress={manipulateNumber}
                             value={newPin}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Confirm PIN</label>
                        <div>
                            <input
                             type="password" 
                             className="form-control" 
                             required="required"
                             autoComplete="autocomplete" 
                             maxLength="4"
                             name="newPinAgain" 
                             onChange={onChange}
                             onKeyPress={manipulateNumber} 
                             value={newPinAgain}
                            />
                        </div>
                    </div>

                    {error}

                    <div style={{marginTop:'150px'}}>
                        <div className="row" style={{marginTop: '30px'}}>
                        <div className="col-lg-4" style={{paddingLeft:'0'}}>
                            <button className="form-back full-width"  onClick={onClickPrev}>
                            <Left className="right-five" />  back
                            </button>
                        </div>
                        <div className="col-lg-8" style={{paddingRight:'0', paddingLeft:'0'}}>
                            <button className="form-continue full-width" onClick={onClickNext} disabled={disabled}>
                               Continue 
                            </button>
                        </div>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
    )
}

export default PinSetup
