import React, {useContext} from 'react';
import {ReactComponent as Left} from '../../../assets/icon/left.svg';
import { AgentCreationContext } from '../AgentCreation';
import {ReactComponent as Add} from '../../../assets/icon/add.svg';
import {ReactComponent as Right} from '../../../assets/icon/right.svg';


const UtilityUpload = () => {
    const {onClickNext, onClickPrev, onFileChange, state:{utilityBill, upload}} = useContext(AgentCreationContext);
    let disabled = true;
    if(utilityBill){
        disabled=false
    }
    return (
        <div>
            <div className="document-upload">
                <h3 className="agent-sign">Documents Upload</h3>
                <p className="agent-info cursor-pointer"  style={{color: '#EB0029', lineHeight:'50px'}} onClick={onClickNext} >Skip for now <Right className="left-five" /></p>
            </div>

            <div className="sub-head">
                <h4 className="agent-know">Upload Utility Bill</h4>
                <p className="agent-info" >Please ensure details are cleanly captured and visible.Kindly upload in <strong> Jpeg, Jpg or Png</strong> formats only</p>

            </div>

            <form>
                <div>
                        <input  
                            type="file" 
                            id="utility-btn" 
                            accept=".png, .jpg, .jpeg" 
                            hidden
                            onChange={onFileChange}
                            name="utilityBill"
                        />

                        <label htmlFor="utility-btn" className="display-block">
                            <div className="row cursor-pointer" >
                                <div className="col-lg-2 nopadding">
                                    {
                                        utilityBill ?
                                        <img className="img-div" src={utilityBill} alt="img" />
                                        :
                                        <div className="add-div">
                                            <Add className="add-icon" />
                                        </div>
                                    }
                                    

                                </div>

                                <div style={{marginTop:'14px'}}>
                                    <h4 className="agent-know">Select image</h4>
                                </div>

                            </div>
                        </label>
                    </div>

                {/* <div className="row cursor-pointer">
                    <div className="col-lg-2 nopadding">
                        <div className="add-div">
                            <Add className="add-icon" />
                        </div>
                    </div>

                    <div style={{marginTop:'14px'}}>
                        <h4 className="agent-know">Select image</h4>
                    </div>
                </div> */}

{
                    upload ?
                    <div className="form-group">
                        <button className="form-continue full-width">
                            Uploading Image......
                             {/* <MakingPayment /> */}
                        </button>
                    </div>
                    :
                    null
                }

                <div style={{marginTop:'250px'}}>
                    <div className="row" style={{marginTop: '30px'}}>
                    <div className="col-lg-4" style={{paddingLeft:'0'}}>
                            <button className="form-back full-width"  onClick={onClickPrev}>
                            <Left className="right-five" />  back
                            </button>
                        </div>
                        <div className="col-lg-8" style={{paddingRight:'0', paddingLeft:'0'}}>
                            <button className="form-continue full-width" onClick={onClickNext} disabled={disabled}>
                               Continue 
                            </button>
                        </div>
                    </div>
                </div>

            </form>
            
        </div>
    )
}

export default UtilityUpload
