import React, { Component, Fragment } from 'react';
import swal from 'sweetalert';
import Login from './Login';
import AgentSetup from './AgentSetup';
import Otp from './Otp';
import baseUrl from '../../baseUrl';
import LoginPageLayoutWrapper from '../../Components/LoginPageLayoutWrapper/loginPageLayoutWrapper';
import SecurityQuestions from './SecurityQuestions';
import PasswordReset from './PasswordReset';
import Options from './Options';
import Modal from '../../Components/UI/Modal/Modal';
import TermsConditions from './TermsConditions';

class MainLogin extends Component {
  constructor(){
    super()
    this.state = {
      username: "",
      password: "",
      otp: "",
      route:"",
      // setupStep: 1,
      newPin: "",
      newPinAgain: '',
      newPassword: '',
      newPasswordAgain: '',
      loggingIn: false,
      userType: '',
      loginError: false,
      loginDesc: '',
      phoneNumber:'',
      securityQuestions: [],
      newQuestions:[],
      question1Id:'',
      newId:'',
      question2Id:'',
      question3Id:'',
      answer1:'',
      answer2:'',
      answer3:'',
      agentId:'',
      agentAnswer:'',
      questionName:'',
      questionId:'',
      termsModal: false,
      latestTerms:''
    }
  }

componentDidMount (){
  sessionStorage.clear();

  //Comment this back in for self onboard
  fetch(`${baseUrl}/oauth/all-questions`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
  }).then(response => response.json())
    .then(questions => {
      let newArray = [];
      for(let i = 0; i<questions.respBody.length; i++){
        newArray[i] = questions.respBody[i]
        newArray[i].status = false
        newArray[i].refId = ""
      }
      this.setState({securityQuestions: questions.respBody, newQuestions: newArray})
      // console.log(this.state.securityQuestions)
    })
    .catch(err => {
      swal('Error', `${err}`, 'info')
    });
  
}

onReturnLogin = (e) => {
  e.preventDefault();
  this.setState({route: ""})
}

AgentSetupButton = (e) => {
  e.preventDefault();
  this.setState({loginError: false})
  let reqBody = {
    newPassword: this.state.newPasswordAgain,
    newPin: this.state.newPinAgain,
    username: this.state.username,
    agentId: this.state.agentId,
    agentSecurityQuestionDto :{
      agentId: this.state.agentId,
      answer1: this.state.answer1,
      answer2: this.state.answer2,
      answer3: this.state.answer3,
      question1Id: this.state.question1Id,
      question2Id: this.state.question2Id,
      question3Id: this.state.question3Id
    }
  };

    if (this.state.newPassword === '' || this.state.newPasswordAgain === '' || this.state.newPin === '' || this.state.newPinAgain === '' || this.state.answer1 === '' || this.state.answer2 === '' || this.state.answer3 === '' ||this.state.question1Id === '' || this.state.question2Id === '' || this.state.question3Id === ''){
      swal("Failed Attempt", "Please fill all fields", "error")
    }
    else if ((this.state.newPassword !== this.state.newPasswordAgain) || (this.state.newPin !== this.state.newPinAgain)) {
      swal("Failed Attempt", "Passwords or Pins do not match", "error")
    } 
    else if ((this.state.newPassword === this.state.newPasswordAgain) && (this.state.newPin === this.state.newPinAgain)) {
      this.setState({loggingIn: true})
      fetch (`${baseUrl}/oauth/agentSetup`, {
        method: 'post',
        headers: {'Content-Type' : 'application/json'}, 
        body: JSON.stringify(reqBody)
      }).then (response => response.json())
      .then(
        setupInformation => {
        if (setupInformation.respCode === "00") {
          swal("You're all set!", "Account setup completed", "success")
          // this.setState({route: 'login', loggingIn: false})
         this.autoLogin()
        } else {
          this.setState({route: 'AgentSetup', loggingIn: false, loginError: true})
           swal('An Error Occured', `${setupInformation.respDescription}`, 'error')
        }
      })
      .catch(err => {
        this.setState({loggingIn: false})
        swal('An Error Occured', `${err}`, 'error')
      });
    }
  // }
}

otpButton = (e) => {
  e.preventDefault();
  this.setState({loginError: false})

  if (this.state.otp === ''){
    swal("Failed Attempt", "Please fill all fields", "error")
  } else {
    this.setState({loggingIn: true})
    let reqBody = {
      username: this.state.username,
      password: this.state.password,
      otp: this.state.otp,
      answer: this.state.answer
    };
    fetch(`${baseUrl}/oauth/logon`, {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(reqBody)
    })
    .then(response => response.json())
      .then(user => {
        if(user.respCode === '00'){
         
          if(user.respBody.isFirstTime === 'false' && user.respBody.isNewDevice === 'false'){
            sessionStorage.setItem('userDetails', JSON.stringify(user.respBody));
            this.setState({loggingIn: false});
            this.checkTermsAndConditions();
            // this.renderRedirect();
          } 
          else  {
            this.setState({loggingIn: false, loginError: true})
          } 
        } 
        else if(user.respCode === '96'){
            this.setState({loggingIn: false, loginError: true, loginDesc: user.respDescription})
            // alert('wrong pin')
          }
      })
      .catch(err => {
        this.setState({loggingIn: false})
        swal('An Error Occured', `${err}`, 'info')
      })
  }
  ;
}

securityQuestionButton = (e) => {
  e.preventDefault();
  this.setState({loginError: false})

  if (this.state.agentAnswer === ''){
    swal("Failed Attempt", "Please fill all fields", "error")
  } else {
    this.setState({loggingIn: true})
    let reqBody = {
      agentAnswer: this.state.agentAnswer,
      agentId: this.state.agentId,
      questionId: this.state.questionId
    };
    fetch(`${baseUrl}/oauth/verify-agent-answer`, {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(reqBody)
    })
    .then(response => response.json())
      .then(user => {
        if(user.respCode === '00'){
         
          if(user.respBody.isFirstTime === 'false' && user.respBody.isNewDevice === 'false'){
            sessionStorage.setItem('userDetails', JSON.stringify(user.respBody));
            this.setState({loggingIn: false});
            this.checkTermsAndConditions();
            // this.renderRedirect();
          } 
          else  {
            this.setState({loggingIn: false, loginError: true})
          } 
        } 
        else if(user.respCode === '96'){
            this.setState({loggingIn: false, loginError: true, loginDesc: user.respDescription})
            // alert('wrong pin')
          }
      })
      .catch(err => {
        this.setState({loggingIn: false})
        swal('An Error Occured', `${err}`, 'info')
      })
  }
  ;
}

onChange = (event) => { 
  this.setState({[event.target.name]: event.target.value});
}

onSelectChange = (e) =>{
  const {newQuestions} = this.state

  const id = e.target[e.target.selectedIndex].id
// console.log(id)
  // this.setState({

  // })

  let tempArray = []
  for (let i = 0; i<newQuestions.length; i++){
    tempArray[i] = newQuestions[i]
    if(tempArray[i].refId == e.target.id){
       tempArray[i].status = false
       tempArray[i].refId = ""
    }
    if(id == tempArray[i].id){
      
      tempArray[i].status = !tempArray[i].status
      tempArray[i].refId = e.target.id
    }
  }
  // console.log('temp', tempArray)

  this.setState({
    // [e.target.name]: e.target.value,
    newQuestions: tempArray,
    [e.target.name]: e.target.value,
    newId: id
  })

  // console.log(this.state.question1Id)
  e.target.options[0].selected = true

  // let optionId = newQuestions.find(question => question.id.match((id)))
  // console.log(optionId)

}

loginButtonClick = (e) => {
  e.preventDefault();
  this.setState({loginError: false})
  let reqBody = {
    username: this.state.username,
    password: this.state.password,
    otp: this.state.otp
    };

    if(reqBody.username === '' || reqBody.password === ''){
      swal("Login Failed", "Username and Password Fields cannot empty", "error")
    } else {
      this.setState({loggingIn: true});
    fetch(`${baseUrl}/oauth/logon`, {
    method: 'post',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(reqBody)
  }).then(response => response.json())
    .then(user => {
        this.setState({loggingIn: false})
        if(user.respCode === '00')
        {
          this.setState({
            userType: user.respBody.userType.toLowerCase(),
            phoneNumber:user.respBody.phoneNumber,
            agentId: user.respBody.agentId
          })
          if(user.respBody.isFirstTime === 'true'){
              this.setState({route: 'AgentSetup'})
              sessionStorage.setItem('userDetails', JSON.stringify(user.respBody));
              // console.log(sessionStorage.getItem('userDetails'))
          } 
          else {
            if(user.respBody.resetPassword === true){
              if(user.respBody.securityQuestionAnswered === true){
                this.getSecurityQuestion();
                this.setState({route: 'password-reset'})
                sessionStorage.setItem('auth_token', JSON.stringify(user.respBody.auth_token));
              }
              else{
                swal("Ooops!", 'Kindly contact support to set security questions...', 'info')
              }
            }
            else if(user.respBody.resetPassword === false && user.respBody.isNewDevice === 'true') {
              if(user.respBody.securityQuestionAnswered === false){
                this.setState({
                  route: 'otp'
                })
              }
              else{
                this.getSecurityQuestion();
                this.setState({route: 'options'})
              }
            } 

            else if (user.respBody.resetPassword === false && user.respBody.isNewDevice === 'false'){
              sessionStorage.setItem('userDetails', JSON.stringify(user.respBody));
              this.checkTermsAndConditions();
              // this.renderRedirect();
              // console.log(sessionStorage.getItem('userDetails'))
            }
          }  
       }
       else if(user.respCode === '96'){
          this.setState({route: 'login', loginError: true, loginDesc: user.respDescription}) 
        } else {
          this.setState({route: 'login', loginError: true})
        } 
    }).catch(err => {
      this.setState({loggingIn: false})
      swal("Login Failed", 'An error occured, please try again...', 'error')
    })
    }
}

autoLogin = () => {
  let reqBody = {
  username: this.state.username,
  password: this.state.newPassword,
  otp: this.state.otp
  };

  fetch(`${baseUrl}/oauth/logon`, {
  method: 'post',
  headers: {'Content-Type': 'application/json'},
  body: JSON.stringify(reqBody)
  }).then(response => response.json())
  .then(user => {
      if(user.respCode === '00')
      {
        this.setState({
          userType: user.respBody.userType.toLowerCase(),
          phoneNumber:user.respBody.phoneNumber,
          agentId: user.respBody.agentId
        })

        sessionStorage.setItem('userDetails', JSON.stringify(user.respBody));
        this.checkTermsAndConditions();
        // this.renderRedirect();
        // console.log(sessionStorage.getItem('userDetails'))
      }
      else if(user.respCode === '96'){
        this.setState({route: 'login'})
      }
  })
  .catch(err => {
      this.setState({route: 'login'})
  })
}
routeChange = (route) => { 
  this.setState({route: route})
}

getSecurityQuestion = () =>{
  fetch(`${baseUrl}/oauth/agent-question/${this.state.agentId}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
  }).then(response => response.json())
    .then(question => {
      this.setState({
        questionName: question.respBody.questionName,
        questionId: question.respBody.id
      })
      console.log(this.state.questionName)
    })
    .catch(err => {
      swal('Error', `${err}`, 'info')
    });
}

// Manipulate Number input fields and Password fields for Pin to not accept anything other than numbers
manipulateNumber = (e) => {
  var inputKeyCode = e.keyCode ? e.keyCode : e.which;
  if (((inputKeyCode >= 48 && inputKeyCode <= 57) || (inputKeyCode >= 96 && inputKeyCode <= 105)) && (inputKeyCode != null)){
      if((e.target.value.length === e.target.maxLength) || (inputKeyCode === 45)){
      e.preventDefault();
    }
  } else {
    e.preventDefault();
  }
}

renderRedirect = () => {
  const { userType } = this.state;
  const { securityQuestionAnswered } = JSON.parse(sessionStorage.getItem('userDetails'));
  if(securityQuestionAnswered){
    if (userType === 'aggregator' ) {
      this.props.history.push("/aggregator");
      } 
      else if (userType === 'sub agent' || userType === 'sub-agent' || userType === 'subagent' || userType === 'sole' || userType === 'sub_agent'){
        this.props.history.push("/dashboard");
      } 
      else if (userType === 'subaggregator'){
        this.props.history.push("/sub-aggregator");
      }
      else {
        swal("Login Failed", 'User type unknown', 'error')
      }
  }else{
    this.props.history.push("/set-question");
  }
  
}

checkTermsAndConditions = () =>{
  fetch(`${baseUrl}/terms/checkTermsAcceptance/${this.state.agentId}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
  }).then(response => response.json())
    .then(terms => {
      this.setState({
        hasAcceptedTerms: terms.hasAcceptedTerms,
        latestTerms: terms.latestTerms
      })
      if(terms.hasAcceptedTerms){
        this.renderRedirect()
        
      }else{
        this.setState({
          termsModal: true
        })
      }
    })
    .catch(err => {
      swal('Error', `${err}`, 'info')
    });
}

onCancelTerms = () =>{
  sessionStorage.clear();
  this.setState({
    termsModal: false
  })
}

onAcceptTerms = (e) =>{
  e.preventDefault();
  this.setState({
    loggingIn: true,
  })
  fetch(`${baseUrl}/terms/termsAccepted/${this.state.agentId}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
  }).then(response => response.json())
    .then(terms => {
      if(terms.respCode === '00'){
        this.setState({
          termsModal: false,
          loggingIn: false
        })
        this.renderRedirect()
      }
    })
    .catch(err => {
      swal('Error', `${err}`, 'info')
    });
}


  render() {
    const { loggingIn, loginError, route, loginDesc, phoneNumber, newQuestions, questionName, termsModal, latestTerms, submit } = this.state;

    return (
      <>
        <Modal open = {termsModal} className="left-right">
          <TermsConditions
            latestTerms={latestTerms}
            submit={loggingIn}
            onAcceptTerms={this.onAcceptTerms}
            goBack = {this.onCancelTerms}
          />
        </Modal>   
        <LoginPageLayoutWrapper>        
          <Fragment>       
            {
              (() => {
                switch(route){
                  case 'AgentSetup':
                    return <AgentSetup 
                      onChange={this.onChange} 
                      AgentSetupButton={this.AgentSetupButton} 
                      routeChange={this.routeChange}
                      loggingIn = {loggingIn}
                      loginError = {loginError}
                      manipulateNumber={this.manipulateNumber} 
                      onReturnLogin={this.onReturnLogin}
                      state={this.state}
                      onSelectChange = {this.onSelectChange}
                    
                  />
                  case 'otp':
                    return <Otp 
                      onChange={this.onChange}   
                      otpButton={this.otpButton} 
                      loginDesc = {loginDesc}
                      loginError={loginError} 
                      loggingIn={loggingIn} 
                      manipulateNumber={this.manipulateNumber}
                      phoneNumber={phoneNumber}
                  />
                  case 'options':
                    return <Options 
                      routeChange = {this.routeChange}
                    />
                  case 'security-questions':
                    return <SecurityQuestions
                      onChange={this.onChange} 
                      securityQuestionButton={this.securityQuestionButton}
                      questionName={questionName} 
                      loginError={loginError} 
                      loggingIn={loggingIn} 
                      loginDesc = {loginDesc}
                    />
                  case 'password-reset':
                    return <PasswordReset 
                      onChange={this.onChange} 
                      // questionName={questionName}
                      state={this.state}
                      routeChange={this.routeChange}
                    />
                  default:
                    return <Login 
                      loginButtonClick={this.loginButtonClick} 
                      onChange={this.onChange} 
                      routeChange={this.routeChange}
                      loggingIn = {loggingIn}
                      loginError = {loginError}
                      loginDesc = {loginDesc}
                    />;
                }
              })()                              
            }
          </Fragment>
        </LoginPageLayoutWrapper> 
      </>
    );  
  }
}

export default MainLogin;