import React from 'react';
import './loginError.css';

const LoginError = (props) => {
    return (
        <div className="alert alert-danger alert-dismissible out" id="loginError">
            {/* Please enter correct credentials. */}
            {props.loginDesc}
        </div>
    )
}

export default LoginError;