import React, {useContext} from 'react';
import {ReactComponent as Left} from '../../../assets/icon/left.svg';
import {ReactComponent as User} from '../../../assets/icon/user.svg';
import { AgentCreationContext } from '../AgentCreation';


const BusinessInfo = () => {
    const {state:{states, stateLga, businessState, businessLga,businessLandmark, businessLocation, businessName, firstName}, onClickNext, onClickPrev, onChange} = useContext(AgentCreationContext);
    let disabled = true;
    if(businessName.trim() === "" || businessState.trim() === "" || businessLocation.trim() === "" || businessLandmark.trim() === "" || businessLga.trim() === ""){
        disabled = true
    }else{
        disabled = false
    }
    return (
        <div>
            <div>
                <h3 className="agent-sign">Business Information</h3>
            </div>
            <div className="info-div">
                <p className="agent-info"><User className="user-icon" /> <span style={{fontSize:'18px'}}>Hello <strong>{firstName},</strong></span> Please Fill in your business <strong>details</strong> below</p>
            </div>

            <div>
                <form>
                    <div className="form-group">
                        <label>Business Name</label>
                        <div>
                            <input
                            type="text" 
                            required="required" 
                            className="form-control"
                            onChange={onChange}
                            name="businessName"
                            value={businessName} 
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Address</label>
                        <div>
                            <input
                            type="text" 
                            required="required" 
                            className="form-control" 
                            onChange={onChange}
                            name="businessLocation"
                            value={businessLocation}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>State</label>
                        <div>
                            <select
                                required
                                className="form-control" 
                                onChange={onChange}
                                name="businessState"
                                value = {businessState}
                            >
                                <option value="">Choose state</option>
                                {
                                    states ? 
                                    states.map((state, i)=>{
                                        return <option
                                                    value={state.state.name}
                                                    key={i}
                                                >
                                                    {state.state.name}
                                                </option>
                                    }) 
                                    :
                                    null 
                                }
                            </select>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>L.G.A</label>
                        <div>
                        <select
                            required
                            className="form-control" 
                            onChange={onChange}
                            name="businessLga"
                            value = {businessLga}
                            >
                                <option value="">Choose l.g.a</option>
                                {
                                    stateLga ? 
                                    stateLga.map((local, i)=>{
                                        return <option
                                                    value={local.name}
                                                    key={i}
                                                >
                                                    {local.name}
                                                </option>
                                    }) 
                                    :
                                    null 
                                }
                            </select>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Nearest Landmark/Bustop</label>
                        <div>
                            <input
                            type="text" 
                            required="required" 
                            className="form-control"
                            onChange={onChange}
                            name="businessLandmark"
                            value = {businessLandmark} 
                            />
                        </div>
                    </div>

                    <div className="row" style={{marginTop: '30px'}}>
                        <div className="col-lg-4" style={{paddingLeft:'0'}}>
                            <button className="form-back full-width"  onClick={onClickPrev}>
                            <Left className="right-five" />  back
                            </button>
                        </div>
                        <div className="col-lg-8" style={{paddingRight:'0', paddingLeft:'0'}}>
                            <button className="form-continue full-width" onClick={onClickNext} disabled={disabled}>
                               Continue 
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default BusinessInfo
