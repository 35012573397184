import React, { Fragment } from 'react';
import IsLoading from '../../Components/isLoading/isLoading.js';
import LoginError from '../../Components/loginError/LoginError';
import LoginPageHeader from '../../Components/LoginPageHeader/loginPageHeader.jsx';
import LoginPageFooter from '../../Components/LoginPageFooter/loginPageFooter.jsx';
import PasswordResetLink from '../../Components/PasswordResetLink/passwordResetLink.jsx';

const Otp = ({ onChange, otpButton, loggingIn, loginError, manipulateNumber, loginDesc, phoneNumber }) => {
  const phone = phoneNumber ? phoneNumber.substring(7,11) : '****';
 const loginText=(`Kindly enter OTP sent to your registered email and phone number 0****${phone}.`)
  return (
    <Fragment>
      <div id="login-container">
        <LoginPageHeader text= {loginText} />
        {/* {console.log('p', phoneNumber)} */}
        {/*-- Login Form */}
        <form onSubmit={otpButton}>
          <div className="form-group">
            <div className="col-sm-12 col-md-8 col-lg-8">          
              <input 
                type="password" 
                className="form-control" 
                required="required" 
                id="otp"
                autoFocus="autofocus" 
                placeholder="Enter Pin"
                maxLength="4"
                name="otp" 
                onChange={onChange} 
                onKeyPress={manipulateNumber}
              />
            </div>
          </div>

          <div className="form-group">
            <button 
              type="submit"
              className="btn btn-danger" 
              disabled={loggingIn}
            >
              {
                loggingIn ? <IsLoading />
                : 'Proceed'
              }
            </button>
          </div>
        </form>
        <PasswordResetLink />
        {
          loginError ? 
          <LoginError
          loginDesc= {loginDesc} /> : null
        }
        <LoginPageFooter />
      </div>
    </Fragment>
  )
}

export default Otp;