import React, { Fragment } from 'react'
import './loginPageHeader.styles.scss';
import TtmfbLogo from "../../img/logo-f.png"

export default function LoginPageHeader({ text }) {
    return (
        <Fragment>
            <div className="login-page-header">
                <img src={TtmfbLogo} alt="Freedom Logo" /> <br /><br /><br/>
                <p> { text }</p>
            </div>
        </Fragment>
    )
}
