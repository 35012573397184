import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import {ReactComponent as Info} from '../../../assets/icon/info.svg';
import {ReactComponent as Left} from '../../../assets/icon/left.svg';
import { AgentCreationContext } from '../AgentCreation';
import IsLoading from '../../../Components/isLoading/isLoading';

const BasicInfo = () => {
    const {state:{firstName, lastName, middleName, activePhoneNumber, bvn, submit }, onChange, validateBvn, onClickBvn} = useContext(AgentCreationContext);
    const history = useHistory();
    let disabled = true;
    if(submit || firstName.trim() === "" || lastName.trim() === "" || activePhoneNumber.trim() === "" || bvn.trim() === ""){
        disabled = true
    }else{
        disabled = false
    }
    return (
        <div>
            <div>
                <h3 className="agent-sign">Sign Up as Agent</h3>
            </div>
            <div className="info-div">
                <p className="agent-info">To Start, Please Fill in <strong>details</strong> below</p>
            </div>

            <div>
                <form>
                    <div className="form-group">
                        <label>Enter First Name</label>
                        <div>
                            <input
                            type="text" 
                            required
                            className="form-control"
                            onChange={onChange} 
                            name="firstName"
                            value={firstName}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Enter Middle Name</label>
                        <div>
                            <input
                            type="text" 
                            required 
                            className="form-control" 
                            onChange={onChange} 
                            name="middleName"
                            value={middleName}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Enter Last Name</label>
                        <div>
                            <input
                            type="text" 
                            required
                            className="form-control" 
                            onChange={onChange} 
                            name="lastName"
                            value={lastName}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Enter Phone number</label>
                        <div>
                            <input
                            type="text" 
                            required
                            className="form-control" 
                            onChange={onChange} 
                            name="activePhoneNumber"
                            value={activePhoneNumber}
                            maxLength="11"
                            minLength="11"
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Enter BVN <Info className="five cursor-pointer" onClick={onClickBvn} /></label>
                        <div>
                            <input
                            type="number" 
                            required
                            className="form-control" 
                            onChange={onChange} 
                            name="bvn"
                            value={bvn}
                            maxLength="11"
                            minLength="11"
                            />
                        </div>
                    </div>

                    <div className="row" style={{marginTop: '30px'}}>
                        <div className="col-lg-4" style={{paddingLeft:'0'}} >
                            <button className="form-back full-width" onClick={()=>history.goBack()}>
                              <Left className="right-five" />  back
                            </button>
                        </div>
                        <div className="col-lg-8" style={{paddingRight:'0', paddingLeft:'0'}} >
                            <button className="form-continue full-width" onClick={validateBvn} disabled={disabled}>
                                {
                                    submit ? <IsLoading />
                                    :
                                    'Continue'
                                }
                            </button>
                        </div>
                    </div>

                    <div className="inquiry-div">
                        <p className="inquiry" onClick={onClickBvn}>Why we need your BVN?</p>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default BasicInfo
