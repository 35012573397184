import React, { Component } from 'react';
import baseUrl from '../../baseUrl';
import IsLoading from '../../Components/isLoading/isLoading.js';
import swal from 'sweetalert';
import LoginError from '../../Components/loginError/LoginError';
import LoginPageHeader from '../../Components/LoginPageHeader/loginPageHeader';
import LoginPageLayoutWrapper from '../../Components/LoginPageLayoutWrapper/loginPageLayoutWrapper';

class ResetPassword extends Component {
  _isMounted = false;
    constructor(){
    super()
    this.state = {
      loggingIn: false,
      loginError: false,
      loginDesc:'',
      agentName: ''
    }
  }
  onChange = (event) => { this.setState({[event.target.name]: event.target.value});}

  resetPassword = (e) => {
  this.setState({loginError: false})

  let reqBody = {
	agentName: this.state.agentName,
   };

  if (this.state.agentName === ''){
    swal("Failed Attempt", "Please enter your username", "info")
  } else {
    ;
    this.setState({loggingIn: true})
    fetch (`${baseUrl}/oauth/forgotpassword`, {
      method: 'post',
      headers: {
        'Content-Type' : 'application/json'
      }, 
      body: JSON.stringify(reqBody)
    }).then (response => response.json())
    .then(
      result => {
      if (result.respCode === "00") {
        swal("Successful Operation", "Your password has been reset, check your email for the new password.", "success")
        this.setState({loggingIn: false})
        this.props.history.push("/");
        ;
      } else if (result.respCode === "96") {
        this.setState({loggingIn: false, loginError: true, loginDesc: result.respDescription})
        ;
      } else {
        swal('An Error Occured', 'There was an error while processing this request, please try again', 'error')
        this.setState({loggingIn: false, loginError: true})
        ;
      }
    })
    .catch(err => {
      this.setState({loggingIn: false})
      ;
      swal('An Error Occured', 'There was an error while processing this request, please try again', 'error')
    });
  }
}

  render() {
    const { loginError, loggingIn, loginDesc } = this.state;
    return (
      <LoginPageLayoutWrapper>
        <div id="login-container">
          <LoginPageHeader text="Reset Password" />

          {/*-- Agent Setup Form */}
          <div>
            <div className="form-group">
              <div className="col-sm-12 col-md-8 col-lg-8">
                <input 
                  type="text" 
                  className="form-control" 
                  required="required"
                  placeholder="Enter your username"
                  autoComplete="autocomplete"
                  autoFocus="autofocus"
                  maxLength=""
                  name="agentName"
                  onChange={this.onChange} />
              </div>
            </div> <br/>

            <div className="form-group">
              <button 
                type="submit" 
                className="btn btn-danger"
                onClick={this.resetPassword}>
                {
                  loggingIn ? <IsLoading />
                  : 'Proceed'
                }
              </button>
            </div>
            {
              loginError ? <LoginError loginDesc={loginDesc} /> : null
            }
          </div>
        </div>
      </LoginPageLayoutWrapper>
    )
  }
}

export default ResetPassword;