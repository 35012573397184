import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'

const SelfOnboard = () => {
    return (
        <Fragment>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div style={{marginTop: '15px'}}>
                <h6><Link to="/signup" style={{color: '#ff0014'}}><strong>Become a Freedom Agent</strong></Link></h6> 
                {/* <h6>Don't have an account? <Link to="/signup" style={{color: '#ff0014'}}> Sign up </Link></h6>  */}
                </div>
            </div>
        </Fragment>
    )
}

export default SelfOnboard
