import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

export default function PasswordResetLink() {
    return (
        <Fragment>
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div>
                <h6>Can't remember your Password? <Link to="/resetPassword" style={{color: '#ff0014'}}> Reset it </Link></h6> 
                </div>
            </div>
        </Fragment>
    )
}
