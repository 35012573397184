import React from 'react';
import './accord.scss';
import {ReactComponent as Up} from '../../../assets/icon/up.svg';
import {ReactComponent as Down} from '../../../assets/icon/down.svg';

const Accordion = (props) => {
    return (
        <div className="accordion-div">
            <div className="accordion-title" onClick={props.clicked}>
                <h4 className="accordion-bvn">{props.title}</h4>
                {
                    props.show ?
                    <Up />
                    :
                    <Down />
                }

            </div>
            {
                props.show ?
                <div className="accordion-content">
                    {props.children}
                </div>
                :
                null
            }
                
        </div>
    )
}

export default Accordion
