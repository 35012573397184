import React, {useContext} from 'react';
import {ReactComponent as Left} from '../../../assets/icon/left.svg';
import { PasswordContext } from '../PasswordReset';
import IsLoading from '../../../Components/isLoading/isLoading.js';


const NewPasswordSetup = () => {
    const {onChange, newPassword, newPasswordAgain, onChangePassword, loggingIn} = useContext(PasswordContext)
    let disabled = true;
    let error = null
    let passwordStrength=null //To confirm the password strength
    let passValidate= false; //To control the form validation for the disabled button


    // let pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    let pattern = new RegExp("^(?=.{8,})");

    if(newPassword){
        if (!pattern.test(newPassword)) {

            passwordStrength = (<p className="agent-info" style={{color:'#ff0014'}} >Password not strong! Ensure your password contains at least 8 characters</p>)
            // passwordStrength = (<p className="agent-info" style={{color:'#ff0014'}} >Password not strong! Ensure your password contains at least 8 characters, a number, a special character, upper and lower case letters</p>)
        }else{
            passwordStrength = null
            passValidate = true
        }
    }



    if(newPassword !== '' && newPasswordAgain !== ''){
        if(passValidate){
            if(newPassword !== newPasswordAgain){
                error = (<p className="agent-info" style={{color:'#ff0014'}} >Passwords don't match!!!</p>)
                disabled=true
            }
            else{
                disabled = false
            }
        }
        
    }

    // const 
    
    return (
        <div className="agent-creation">
            <div>
                <h3 className="agent-sign">Change Password</h3>
            </div>

            <div className="sub-head">
                <h4 className="agent-know">We care about Security <span style={{color:'#ff0014'}}>!!!</span></h4>
                <p className="agent-info" >Please replace default Password to your own custom password.</p>

            </div>

            <div>
                <form onSubmit={onChangePassword}>

                                      
                    <div className="form-group">
                        <label>Input new Password</label>
                        <div>
                            <input
                             type="password" 
                             className="form-control" 
                             required="required"
                             autoComplete="autocomplete"
                             autoFocus="autofocus"
                             name="newPassword"
                             onChange={onChange}
                             value={newPassword}
                            />
                        </div>
                    </div>
                    {passwordStrength}
                    <div className="form-group">
                        <label>Confirm Password</label>
                        <div>
                            <input
                             type="password" 
                             className="form-control" 
                             required="required"
                             autoComplete="autocomplete" 
                             name="newPasswordAgain"
                             onChange={onChange}
                             value={newPasswordAgain}
                            />
                        </div>
                    </div>

                    {error}

                    <div style={{marginTop:'150px'}}>
                        <div className="row" style={{marginTop: '30px'}}>
                        {/* <div className="col-lg-4" style={{paddingLeft:'0'}}>
                            <button className="form-back full-width" onClick={onReturnLogin}>
                            <Left className="right-five" />  back
                            </button>
                        </div> */}
                        <div className="col-lg-12" style={{paddingRight:'0', paddingLeft:'0'}}>
                            <button className="form-continue full-width" onClick={onChangePassword} disabled={disabled}>
                               {
                                   loggingIn ? <IsLoading />
                                   :
                                    'Continue '
                               }
                            </button>
                        </div>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
    )
}

export default NewPasswordSetup
