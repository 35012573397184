import React, {useContext} from 'react';
import {ReactComponent as Left} from '../../../assets/icon/left.svg';
import { AgentCreationContext } from '../AgentCreation';
import {ReactComponent as Add} from '../../../assets/icon/add.svg';
// import MakingPayment from '../../../Components/makingPayment/makingPayment';

const SelectedDocumentUpload = () => {
    const {onClickNext, onCancelDoc, onChange, onFileChange, state:{identificationType, idCard, idCardBack, idNumber, upload}} = useContext(AgentCreationContext);
// console.log(idCard)
    let disabled = true;
    if(idCard && idCardBack && idNumber){
        disabled=false
    }
    return (
        <div>
            <div className="document-upload">
                <h3 className="agent-sign">Documents Upload</h3>
            </div>

            <div className="sub-head">
                <h4 className="agent-know">{identificationType} </h4>
                <p className="agent-info" >Please click below to select document images, ensure details are cleanly captured and visible. Kindly upload in <strong> Jpeg, Jpg or Png</strong> formats only</p>

            </div>

            <form>
                <div className="form-group">
                    <label>Document ID</label>
                    <div>
                        <input
                        type="text" 
                        required="required" 
                        className="form-control"
                        onChange={onChange}
                        name="idNumber"
                        value = {idNumber} 
                        />
                    </div>

                </div>
                <p className="agent-info" >Please, enter document ID to continue</p>
                
                
                <div className="up-thirty">
                    <input  
                        type="file"
                        accept=".png, .jpg, .jpeg" 
                        id="front-btn" 
                        hidden
                        onChange={onFileChange}
                        name="idCard"
                    />

                    <label htmlFor="front-btn" className="display-block">
                        <div className="row cursor-pointer" >
                            <div className="col-lg-2 nopadding">
                                {
                                    idCard ?
                                     <img className="img-div" src={idCard} alt="img" />
                                     :
                                    <div className="add-div">
                                        <Add className="add-icon" />
                                    </div>
                                }
                                

                            </div>

                            <div style={{marginTop:'14px'}}>
                                <h4 className="agent-know">Front</h4>
                            </div>

                        </div>
                    </label>
                </div>
                

                <div className="up-thirty">
                    <input  
                        type="file" 
                        accept=".png, .jpg, .jpeg" 
                        id="back-btn" 
                        hidden
                        onChange={onFileChange}
                        name="idCardBack"
                    />

                    <label htmlFor="back-btn" className="display-block">
                        <div className="row cursor-pointer" >
                            <div className="col-lg-2 nopadding">
                                {
                                    idCardBack ?
                                     <img className="img-div" src={idCardBack} alt="img" />
                                     :
                                    <div className="add-div">
                                        <Add className="add-icon" />
                                    </div>
                                }
                            </div>

                            <div style={{marginTop:'14px'}}>
                                <h4 className="agent-know">Back</h4>
                            </div>

                        </div>
                    </label>
                </div>


                {
                    upload ?
                    <div className="form-group">
                        <button className="form-continue full-width">
                            Uploading Image......
                             {/* <MakingPayment /> */}
                        </button>
                    </div>
                    :
                    null
                }


                {/* <div className="row cursor-pointer up-thirty">
                    <div className="col-lg-2 nopadding">
                        <div className="add-div">
                            <Add className="add-icon" />
                        </div>
                    </div>

                    <div style={{marginTop:'14px'}}>
                        <h4 className="agent-know">Back</h4>
                    </div>
                </div> */}

                    <div className="row" style={{marginTop: '50px'}}>
                    <div className="col-lg-4" style={{paddingLeft:'0'}}>
                            <button className="form-back full-width"  onClick={onCancelDoc}>
                            <Left className="right-five" />  back
                            </button>
                        </div>
                        <div className="col-lg-8" style={{paddingRight:'0', paddingLeft:'0'}}>
                            <button className="form-continue full-width" onClick={onClickNext} disabled={disabled}>
                               Continue 
                            </button>
                        </div>
                    </div>

            </form>
            
        </div>
    )
}

export default SelectedDocumentUpload
