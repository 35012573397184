import React, { Component, lazy, Suspense } from 'react';
import { Route, Switch, HashRouter, Redirect } from 'react-router-dom';

import './css/main.scss';
import "react-datepicker/dist/react-datepicker.css";


// Routes for Login and setups
import MainLogin  from './pages/Login/MainLogin';
import ChangePassword from './pages/Header/ChangePassword';
import ResetPassword from './pages/Header/ResetPassword';
import AgentCreation from './pages/AgentOnboarding/AgentCreation';
import ChangePin from './pages/Header/ChangePin';
import SecurityQuestionSet from './pages/Header/SecurityQuestionSet';

//Performance Optimization components
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';
import Spinner from './Components/PreLoader/preLoader';

// Agent Verification Route
const AgentVerificationComponent = lazy(() => import('./pages/AgentVerification/agent-verification.component'));

// Routes for Aggregator Portal
const Aggregator = lazy(() => import('./pages/Aggregator/AggregatorDashboard/AggregatorDashboard')) ;
const AggregatorView = lazy(() => import('./pages/Aggregator/AggregatorViewAgent/AggregatorView'));
const AggregatorIncomeWallet = lazy(() => import('./pages/Aggregator/AggregatorIncomeWallet/AggregatorIncomeWallet'));
const AggregatorTradingWallet = lazy(() => import('./pages/Aggregator/AggregatorTradingWallet/AggregatorTradingWallet'));
const AggregatorAllAgentTransactions = lazy(() => import('./pages/Aggregator/AggregatorAllTransactions/AggregatorAllAgentTransactions'));
const AggregatorTransfer = lazy(() => import('./pages/Aggregator/AggregatorTransfer/AggregatorTransfer'));
const AggregatorReceipt = lazy(() => import('./pages/Aggregator/Receipts/PrintReceipt')) ;
const AggregatorBillPaymentReceipt = lazy(() => import('./pages/Aggregator/Receipts/bill-payment-receipt'));
const CreateAgent = lazy(() => import('./pages/Aggregator/CreateAgent/CreateAgent.component'));
const AgentRegistration = lazy(() => import('./pages/AgentRegistration/CreateAgent/CreateAgent.component'));
const ViewEnrolledAgents = lazy(() => import('./pages/Aggregator/ViewEnrolledAgents/ViewEnrolledAgents.component'));
const AssignTerminal = lazy(() => import('./pages/Aggregator/AggregatorViewAgent/AssignTerminal'));
const ViewSubAggregators = lazy(()=>import('./pages/Aggregator/ViewSubAggregators/ViewSubAggregators'));
const AggregatorUploadForm = lazy(()=>import('./pages/Aggregator/AggregatorViewAgent/UploadForm'));


// Routes for Sub Aggregator
const SubAggregator = lazy(() => import('./pages/SubAggregator/AggregatorDashboard/AggregatorDashboard')) ;
const SubAggregatorView = lazy(() => import('./pages/SubAggregator/AggregatorViewAgent/AggregatorView'));
const SubAggregatorAllAgentTransactions = lazy(() => import('./pages/SubAggregator/AggregatorAllTransactions/AggregatorAllAgentTransactions'));
const SubAggregatorTransfer = lazy(() => import('./pages/SubAggregator/AggregatorTransfer/AggregatorTransfer'));
const SubAggregatorReceipt = lazy(() => import('./pages/SubAggregator/Receipts/PrintReceipt')) ;
const SubAggregatorBillPaymentReceipt = lazy(() => import('./pages/SubAggregator/Receipts/bill-payment-receipt'));
const SubAggregatorViewEnrolledAgents = lazy(() => import('./pages/SubAggregator/ViewEnrolledAgents/ViewEnrolledAgents.component'));
const SubAggregatorCreateAgent = lazy(()=> import('./pages/SubAggregator/CreateAgent/CreateAgent.component'));
const SubAggregatorUploadForm = lazy(()=>import('./pages/SubAggregator/AggregatorViewAgent/UploadForm'));



//Routes for Agent Portal
const FundWallet = lazy(() => import('./pages/FundWallet/FundWallet'));
const Bill_payment = lazy(() => import('./pages/Bill_Payment/bill_payment'));
const Transfer = lazy(() => import('./pages/Transfer/transfer'));
const AgentTransfer = lazy(() => import('./pages/Transfer/AgentTransfer'));
const AccountOpening = lazy(() => import('./pages/Account/OpenAnAccount.component'));
const Withdrawal = lazy(() => import('./pages/Withdrawal/withdrawal'));
const Thrift = lazy(() => import('./pages/Thrift/thrift'));
const AgentTradingWallet = lazy(() => import('./pages/Dashboard/AgentTradingWallet/AgentTradingWallet'));
const AgentIncomeWallet = lazy(() => import('./pages/Dashboard/AgentIncomeWallet/AgentIncomeWallet'));
const Receipt = lazy(() => import('./pages/Dashboard/Dashboard/PrintReceipt')) ;
const BillPaymentReceipt = lazy(() => import('./pages/Dashboard/Dashboard/bill-payment-receipt'));
const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard/dashboard'));
const Deposit = lazy(() => import('./pages/Deposit/deposit'));
const Airtime = lazy(() => import('./pages/Airtime/AirtimeTopup'));
const EndOfDay = lazy(() =>import('./pages/EndofDay/endOfDay'));
const CardRequest = lazy(() =>import('./pages/CardRequest/card-request'));
const IssueLog = lazy(() =>import('./pages/IssueLog/issue-log'));
const UpgradeAgent = lazy(() =>import('./pages/UpgradeAgent/UpgradeAgent'));
const WalletStatement = lazy(() =>import('./pages/Statement/statement'));


class App extends Component {
  render() {
    
  // const token = () =>{
  //   if (sessionStorage.getItem('userDetails')){
  //     const {auth_token} = JSON.parse(sessionStorage.getItem('userDetails'));
  //     console.log('authtoken', auth_token)
  //     return true
  //   }
  //   else{
  //     return false
  //   }
    
  // }

  // const checkToken = token()
  // console.log('token', token())
    
    
    const PrivateRoute = ({ component: Component,  ...props }) => (
      <Route {...props} render={(routeProps) => (
        sessionStorage.getItem('userDetails') ? <Component {...routeProps} /> : <Redirect to='/' />
      )} />
    )

    return (
      <HashRouter>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Route exact path="/" component={MainLogin} /> 
              <Route exact path="/verify-agent" component={AgentVerificationComponent} /> 
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/fundWallet" component={FundWallet} />
              <PrivateRoute exact path="/end-of-day-report" component={EndOfDay} />
              <PrivateRoute exact path="/bill_payment" component={Bill_payment} />
              <PrivateRoute exact path="/deposit" component={Deposit} />
              <PrivateRoute exact path="/transfer" component={Transfer} />
              <PrivateRoute exact path="/agentTransfer/:id" component={AgentTransfer} />
              <PrivateRoute exact path="/airtime" component={Airtime} />
              <PrivateRoute exact path="/thrift" component={Thrift} />
              <PrivateRoute exact path="/passwordChange" component={ChangePassword} />
              <Route exact path="/resetPassword" component={ResetPassword} />
              <Route exact path="/signup" component={AgentCreation} />
              <PrivateRoute exact path="/pinChange" component={ChangePin} />
              <PrivateRoute exact path="/set-question" component={SecurityQuestionSet} />
              <PrivateRoute exact path="/upgrade-agent" component={UpgradeAgent} />
              <PrivateRoute exact path="/myTradingWallet/:agentId" component={AgentTradingWallet} />
              <PrivateRoute exact path="/myIncomeWallet/:agentId" component={AgentIncomeWallet} />
              <PrivateRoute exact path='/receipt/:transId' component={Receipt} />
              <PrivateRoute exact path="/bill-payment-receipt/:transId" component={BillPaymentReceipt} />
              <PrivateRoute exact path="/open-an-account" component={AccountOpening} />
              <PrivateRoute exact path="/withdrawal" component={Withdrawal} />
              <PrivateRoute exact path="/statement" component={WalletStatement} />
              <PrivateRoute exact path="/card-request" component={CardRequest} />
              <PrivateRoute exact path="/log-complaint" component={IssueLog} />

              <Route exact path="/agent-registration" component={AgentRegistration} />

              <PrivateRoute exact path="/aggregator" component={Aggregator} />
              <PrivateRoute exact path="/aggregatorTransfer" component={AggregatorTransfer} />
              <PrivateRoute exact path={"/viewAgent/:agentId"} component={AggregatorView} />
              <PrivateRoute exact path={"/incomeWallet/:agentId"} component={AggregatorIncomeWallet} />
              <PrivateRoute exact path="/tradingWallet/:agentId" component={AggregatorTradingWallet} />
              <PrivateRoute exact path={"/allWallet/:agentId"} component={AggregatorAllAgentTransactions} />
              <PrivateRoute exact path='/aggregator-receipt/:transId' component={AggregatorReceipt} />
              <PrivateRoute exact path="/aggregator-bill-payment-receipt/:transId" component={AggregatorBillPaymentReceipt} />
              <PrivateRoute exact path="/createAgent" component={CreateAgent} />
              <PrivateRoute exact path="/viewEnrolledAgents" component={ViewEnrolledAgents} />
              <PrivateRoute exact path="/viewSubAggregators" component={ViewSubAggregators} />
              <PrivateRoute exact path="/assignTerminal" component={AssignTerminal} />
              <PrivateRoute exact path="/aggregatorUploadForm/:id" component={AggregatorUploadForm} />


              <PrivateRoute exact path="/sub-aggregator" component={SubAggregator} />
              <PrivateRoute exact path="/sub-aggregatorViewAgent/:agentId" component={SubAggregatorView} />
              <PrivateRoute exact path="/sub-aggregatorAgentTransactions/:agentId" component={SubAggregatorAllAgentTransactions} />
              <PrivateRoute exact path="/sub-aggregatorTransfer" component={SubAggregatorTransfer} />
              <PrivateRoute exact path="/sub-aggregatorReceipt/:transId" component={SubAggregatorReceipt} />
              <PrivateRoute exact path="/sub-aggregator-bill-payment-receipt/:transId" component={SubAggregatorBillPaymentReceipt} />
              <PrivateRoute exact path="/sub-aggregatorviewAgents" component={SubAggregatorViewEnrolledAgents} />
              <PrivateRoute exact path="/subAggregatorcreateAgent" component={SubAggregatorCreateAgent} />
              <PrivateRoute exact path="/subAggregatorUploadForm/:id" component={SubAggregatorUploadForm} />

              
              <Route path="*" component={MainLogin} />
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </HashRouter>
    );  
  }
}

export default App;