import React, {useContext} from 'react'
import { AgentCreationContext } from '../AgentCreation';

const AgentReferral = () => {
    const {onChange, submitReferral, removeReferral, cancelAddReferral, state:{parentUsername}} = useContext(AgentCreationContext);
    let disabled = true;
    if(parentUsername){
        disabled = false;
    }
    return (
        <div className="bvn-input">
            <h4 className="agent-phone">Were you referred by an Agent ?</h4>
            <p className="agent-info refer" >If Yes, Please enter Agent referral code</p>

            <form>
                <div className="form-group up-thirty">
                    <div className="input-group">
                        <input
                        type="text" 
                        required
                        className="form-control" 
                        onChange={onChange}
                        name="parentUsername"
                        value={parentUsername}
                        />
                    </div>
                </div>
            </form>
            <div className="row bvn-actions">
                    <div className="col-lg-6" style={{paddingLeft:'0'}}>
                        <button className="transparent full-width" onClick={parentUsername ? removeReferral: cancelAddReferral}>
                            {
                                parentUsername ?
                                'Remove code'
                                :
                                'Back'
                            }
                        </button>
                    </div>
                    <div className="col-lg-6" style={{paddingRight:'0', paddingLeft:'0'}}>
                        <button className="form-continue full-width" onClick={submitReferral} disabled={disabled}>
                            Submit
                        </button>
                    </div>
                </div>
        </div>
    )
}

export default AgentReferral
