import React, {useContext} from 'react';
import {ReactComponent as Left} from '../../../assets/icon/left.svg';
import IsLoading from '../../../Components/isLoading/isLoading';
import { AgentCreationContext } from '../AgentCreation';

const PersonalInfo = () => {
    const {state:{email, address, stateOfOrigin, lga, gender, states, stateLga, submit}, onChange, validateEmail, onClickPrev} = useContext(AgentCreationContext);
    let error = false; //To display the email error message
    let emailValidate= true; //To control the form validation for the disabled button
    let disabled = true;

    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if(email === ""){
        error = null
    }else{
        if (!pattern.test(email)) {

            error = true
        }else{
            emailValidate = false

        }
    }

    if(emailValidate || address.trim() === "" || stateOfOrigin.trim() === "" || lga.trim() === "" || gender.trim() === ""){
        disabled = true
    }else{
        disabled = false
    }
    
    return (
        <div>
            <div>
                <h3 className="agent-sign">Personal Information</h3>
            </div>
            <div>
                <h4 className="agent-know" style={{fontSize:'18px'}}>Let’s Know you more</h4>
                <p className="agent-info">Please fill in your Personal information below to get more from <strong>Freedom</strong></p>
            </div>

            <div>
                <form>
                    <div className="form-group">
                        <label>Email Address</label>
                        <div>
                            <input
                            type="email" 
                            required="required" 
                            className="form-control" 
                            name="email"
                            value={email}
                            onChange={onChange}
                            />
                        </div>
                    </div>
                    {error ? (<p className="agent-info" style={{color:'#ff0014'}} >Please enter a valid email address!</p>) : null}
                    <div className="form-group">
                        <label>House Address</label>
                        <div>
                            <input
                            type="text" 
                            required="required" 
                            className="form-control" 
                            onChange={onChange}
                            name="address"
                            value={address}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>State</label>
                        <div>
                            <select
                                required
                                className="form-control" 
                                onChange={onChange}
                                name="stateOfOrigin"
                                value = {stateOfOrigin}
                            >
                                <option value="">Choose state</option>
                                {
                                    states ? 
                                    states.map((state, i)=>{
                                        return <option
                                                    value={state.state.name}
                                                    key={i}
                                                >
                                                    {state.state.name}
                                                </option>
                                    }) 
                                    :
                                    null 
                                }
                            </select>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>L.G.A</label>
                        <div>
                            <select
                                required
                                className="form-control" 
                                onChange={onChange}
                                name="lga"
                                value = {lga}
                                >
                                    <option value="">Choose l.g.a</option>
                                    {
                                        stateLga ? 
                                        stateLga.map((local, i)=>{
                                            return <option
                                                        value={local.name}
                                                        key={i}
                                                    >
                                                        {local.name}
                                                    </option>
                                        }) 
                                        :
                                        null 
                                    }
                            </select>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Gender</label>
                        <div>
                        <select
                            type="text" 
                            required="required" 
                            className="form-control" 
                            onChange={onChange}
                            name="gender"
                            value={gender}
                            >
                                <option value="" >Choose</option>
                                <option value="Female" >Female</option>
                                <option value="Male" >Male</option>
                            </select>
                        </div>
                    </div>

                    <div className="row" style={{marginTop: '30px'}}>
                    <div className="col-lg-4" style={{paddingLeft:'0'}}>
                            <button className="form-back full-width"  onClick={onClickPrev}>
                            <Left className="right-five" />  back
                            </button>
                        </div>
                        <div className="col-lg-8" style={{paddingRight:'0', paddingLeft:'0'}}>
                            <button className="form-continue full-width" onClick={validateEmail} disabled={disabled}>
                                {
                                    submit ? <IsLoading />
                                    :
                                    'Continue'
                                }
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default PersonalInfo
