const baseUrl = "https://freedom.3lineng.com/gravity/api";    //Live Parameter
// const baseUrl = "http://10.2.2.47:9090/api/v1/agent/web/login"; 
// const baseUrl = "http://10.2.2.47:7070/gravity/api"; // Test Parameter for test server
//  const baseUrl = "http://test.3lineng.com:8222/gravity/api"; // Test Parameter for test server
//  const baseUrl = "http://test.3lineng.com:7070/gravity/api"; // Test Parameter for test server
//  const baseUrl = "https://16084b66d91d.ngrok.io/gravity/api"; // Test Parameter for test server
//  const baseUrl = "http://10.9.8.108:8888/gravity/api"; // Another test        
// const baseUrl = "http://10.9.8.69:8081/gravity/api"; // URL Parameter for staging
// const baseUrl = "http://3c939e7114e9.ngrok.io/gravity/api"; // Test Parameter for my localhost 
export default baseUrl;
