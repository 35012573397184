import React from 'react';
import './preLoader.css';

export default () => {
	return (
		<div className="loaderContainer">
			<div className="loader">
				Loading...
			</div>
		</div>
	);
}