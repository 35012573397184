import React,{Fragment} from 'react';
import LoginPageHeader from '../../Components/LoginPageHeader/loginPageHeader.jsx';
import LoginPageFooter from '../../Components/LoginPageFooter/loginPageFooter.jsx';

const Options = ({routeChange}) => {
    return (
        <Fragment>
            <div id="login-container">
                <LoginPageHeader text= 'Select option to login' />
                {/*-- Options */}

                <div className="form-group">
                    <button 
                    type="submit"
                    className="btn btn-warning" 
                    onClick={() => routeChange('otp')}
                    >
                        OTP
                    </button>
                </div>

                <div className="form-group">
                    <button 
                    type="submit"
                    className="btn btn-warning" 
                    onClick={()=>routeChange('security-questions')}
                    >
                        Security Questions
                    </button>
                </div>
                <LoginPageFooter />
            </div>
        </Fragment>
    )
}

export default Options
