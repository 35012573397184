import React, {useContext} from 'react';
import {ReactComponent as Left} from '../../../assets/icon/left.svg';
import { AgentCreationContext } from '../AgentCreation';
import {ReactComponent as Add} from '../../../assets/icon/add.svg';
import {ReactComponent as Right} from '../../../assets/icon/right.svg';
import SelectedDocumentUpload from './SelectedDocumentUpload';


const DocumentUpload = () => {
    const {onClickNext, onClickPrev, onSelectIdType, state:{identificationType}} = useContext(AgentCreationContext);
    // console.log(identificationType)
    return (
        <>
            {
                identificationType === '' ?

                <div>
                    <div className="document-upload">
                        <h3 className="agent-sign">Documents Upload</h3>
                        <p className="agent-info cursor-pointer"  style={{color: '#EB0029', lineHeight:'50px'}} onClick={onClickNext} >Skip for now <Right className="left-five" /></p>
                    </div>

                    <div className="sub-head">
                        <h4 className="agent-know">Tier 2 Information <span style={{color:'#ff0014'}}>!</span></h4>
                        <p className="agent-info" >Please select one below, how you want to be Identified.</p>

                    </div>

                    <form>

                        <div className="row cursor-pointer" onClick={()=>{onSelectIdType('National ID/NIN')}}>
                            <div className="col-lg-2 nopadding">
                                <div className="add-div">
                                    <Add className="add-icon" />
                                </div>
                            </div>

                            <div style={{marginTop:'14px'}}>
                                <h4 className="agent-know">National ID/NIN</h4>
                            </div>
                        </div>

                        <div className="row cursor-pointer up-thirty" onClick={()=>{onSelectIdType('Drivers License')}}>
                            <div className="col-lg-2 nopadding">
                                <div className="add-div">
                                    <Add className="add-icon" />
                                </div>
                            </div>

                            <div style={{marginTop:'14px'}}>
                                <h4 className="agent-know">Drivers License</h4>
                            </div>
                        </div>

                        <div className="row cursor-pointer up-thirty" onClick={()=>{onSelectIdType('International Passport')}}>
                            <div className="col-lg-2 nopadding">
                                <div className="add-div">
                                    <Add className="add-icon" />
                                </div>
                            </div>

                            <div style={{marginTop:'14px'}}>
                                <h4 className="agent-know">International Passport</h4>
                            </div>
                        </div>

                        <div className="row cursor-pointer up-thirty" onClick={()=>{onSelectIdType('Voters Card')}}>
                            <div className="col-lg-2 nopadding">
                                <div className="add-div">
                                    <Add className="add-icon" />
                                </div>
                            </div>

                            <div style={{marginTop:'14px'}}>
                                <h4 className="agent-know">Voters Card</h4>
                            </div>
                        </div>

                        <div style={{marginTop:'70px'}}>
                            <div className="row" style={{marginTop: '30px'}}>
                            <div className="col-lg-4" style={{paddingLeft:'0'}}>
                                    <button className="form-back full-width"  onClick={onClickPrev}>
                                    <Left className="right-five" />  back
                                    </button>
                                </div>
                                <div className="col-lg-8" style={{paddingRight:'0', paddingLeft:'0'}}>
                                    <button className="form-continue full-width" onClick={onClickNext} disabled>
                                    Continue 
                                    </button>
                                </div>
                            </div>
                        </div>

                    </form>
                    
                </div>
            :
                <SelectedDocumentUpload />
                
            }
        </>
    )
}

export default DocumentUpload
