import React, {useContext} from 'react';
import {ReactComponent as Left} from '../../assets/icon/left.svg';
import IsLoading from '../../Components/isLoading/isLoading';
import { AgentSetupContext } from './AgentSetup';

const SetQuestions = () => {
    const {onChange, onSelectChange, onClickPrev, AgentSetupButton, securityQuestions, newId,answer1, answer2, answer3, question1Id, question2Id, question3Id, loggingIn, newQuestions} = useContext(AgentSetupContext)

    // let questions = securityQuestions ? securityQuestions: []
    // console.log(newQuestions)
    // console.log(newId, question2Id)

    return (
        <div>
            <div>
                <h3 className="agent-sign">Set Security Questions</h3>
            </div>

            <div className="sub-head" style={{marginBottom:'0'}}>
                <h4 className="agent-know">Select 3 Questions<span style={{color:'#ff0014'}}> !!!</span></h4>
                <p className="agent-info" >Please select up to 3 questions unique to you and enter answers known by you alone, to ensure security.</p>

            </div>

            <div>
                <form>

                    <div className="form-group mb-05">
                        <label>Question 1</label>
                        <select 
                            className="form-control"
                            onChange = {onSelectChange}
                            name = "question1Id"
                            value={question1Id}
                            required
                            id="q1"
                        >

                        <option value="" selected={true} >{ !question1Id ? 'Select first security question' : newQuestions[question1Id-1].name }</option>
                            
                                 { 
                                    newQuestions ?
                                    newQuestions.filter((question)=>question.status === false).map((question, i)=>{
                                    return  <option
                                            value={question.id}
                                            id={question.id}
                                            key={i}
                                        >
                                            {question.name}
                                        </option>
                                    })
                                    :
                                    null
                                }
                        </select>
                    </div> 

                    <div className="form-group mb-05">
                        <input 
                            type="text" 
                            className="form-control" 
                            required="required"
                            placeholder="Answer 1"
                            name="answer1"
                            value={answer1}
                            onChange={onChange}
                        />
                    </div> 

                    <div className="form-group mb-05">
                    <label style={{marginTop: '15px'}}>Question 2</label>
                        <select 
                            className="form-control"
                            onChange = {onSelectChange}
                            name = "question2Id"
                            value={question2Id}
                            required
                            id="q2"
                        >

                        <option value="" selected={true}>{ !question2Id ? 'Select second security question' : newQuestions[question2Id-1].name }</option>
                        {/* <option value="" selected={true}>Select third security question</option> */}
                            
                                { 
                                    newQuestions ?
                                    newQuestions.filter((question)=>question.status === false).map((question, i)=>{
                                    return  <option
                                            value={question.id}
                                            id={question.id}
                                            key={i}
                                        >
                                            {question.name}
                                        </option>
                                    })
                                    :
                                    null
                                }
                        </select>
                    </div> 

                    <div className="form-group mb-05">
                        <input 
                            type="text" 
                            className="form-control" 
                            required="required"
                            onChange={onChange}
                            placeholder="Answer 2"
                            name="answer2"
                            value={answer2}
                        />
                    </div> 

                    <div className="form-group mb-05">
                    <label style={{marginTop: '15px'}}>Question 3</label>
                        <select 
                            className="form-control"
                            onChange = {onSelectChange}
                            name = "question3Id"
                            value={question3Id}
                            required
                            id="q3"
                        >

                        <option value="" selected={true}>{ !question3Id ? 'Select third security question' : newQuestions[question3Id-1].name }</option>
                            
                                { 
                                    newQuestions ?
                                    newQuestions.filter((question)=>question.status === false).map((question, i)=>{
                                    return  <option
                                            value={question.id}
                                            key={i}
                                            id={question.id}
                                        >
                                            {question.name}
                                        </option>
                                    })
                                    :
                                    null
                                }
                        </select>
                    </div> 

                    <div className="form-group mb-05">
                        <input 
                            type="text" 
                            className="form-control" 
                            required="required"
                            onChange={onChange}
                            placeholder="Answer 3"
                            name="answer3"
                            value={answer3}
                        />
                    </div> 
                    
                    {/* <div style={{marginTop:'40px'}}> */}
                        <div className="row" style={{marginTop: '50px'}}>
                        <div className="col-lg-4" style={{paddingLeft:'0'}}>
                            <button className="form-back full-width"  onClick={onClickPrev}>
                            <Left className="right-five" />  back
                            </button>
                        </div>
                        <div className="col-lg-8" style={{paddingRight:'0', paddingLeft:'0'}}>
                            <button className="form-continue full-width" onClick={AgentSetupButton}>
                            {
                                loggingIn ? <IsLoading />
                                : 'Set up account '
                            }
                            </button>
                        </div>
                        </div>
                    {/* </div> */}
                    
                </form>
            </div>
        </div>
    )
}

export default SetQuestions

              {/* <div className="form-group">
                <button 
                  type="submit" 
                  className="btn btn-danger"
                  disabled={loggingIn}
                >
                  {
                    loggingIn ? <IsLoading />
                    : 'Proceed'
                  }
                </button>
              </div>
              {
                loginError ? <LoginError /> : null
              } */}
                    {/* { 
                            securityQuestions ?
                            securityQuestions.map((question, i)=>{
                              return<div className="d-flex align-items-center mb-6" key={i}>
                              <label className="checkbox-round checkbox-lg checkbox-primary flex-shrink-0 m-0 mr-4">
                              <input type="checkbox" onChange={} />
                              <span></span>  
                              </label>
          
                              <div className="d-flex flex-column flex-grow-1 py-2 mt-15">
                                  <label>{question.name}</label>
                                   <input
                                       type="text" 
                                       className="form-control" 
                                       required="required"
                                       autoComplete="autocomplete"
                                       autoFocus="autofocus"
                                       name={`answer${i+1} ` }
                                       onChange={onChange}
                                    //    value={answer1}
                                      />
                              </div>
                              {console.log(`answer${i+1} `)}
                          </div>
                            })
                            :
                            null
                        } */}
                {/* <div className="d-flex align-items-center mb-6">
                    <label className="checkbox-round checkbox-lg checkbox-primary flex-shrink-0 m-0 mr-4">
                    <input type="checkbox" />
                    <span></span>  
                    </label>

                    <div className="d-flex flex-column flex-grow-1 py-2 mt-15">
                        <label>Input new Password</label>
                         <input
                             type="text" 
                             className="form-control" 
                             required="required"
                             autoComplete="autocomplete"
                             autoFocus="autofocus"
                             name="answer1"
                             onChange={onChange}
                            //  value={newPassword}
                            />
                    </div>
                </div> */}