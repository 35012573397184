import React, {useContext} from 'react'
import { AgentCreationContext } from '../AgentCreation';
import IsLoading from '../../../Components/isLoading/isLoading';



const BvnPhoneInput = () => {
    const {onChange, validateBvn, cancelAddNumber, state:{submit, countcheck, bvnError, bvnPhoneNumber}} = useContext(AgentCreationContext);
    return (
        <div className="bvn-input">
            <h4 className="agent-phone">Before you continue, Please enter <strong>phone number</strong> linked to <strong>BVN</strong></h4>
            <form>
                <div className="form-group up-thirty">
                    <div className="input-group">
                        <input
                        type="text" 
                        required
                        className="form-control" 
                        onChange={onChange}
                        name="bvnPhoneNumber"
                        value={bvnPhoneNumber}
                        minLength="11"
                        maxLength="11"
                        />
                    </div>
                </div>
                
                {bvnError ?  <p className="bvn-error">The phone number entered isn't linked to the BVN provided!!</p> : null}

                
                            
            </form>
            <div className="row bvn-actions">
                    <div className="col-lg-5" style={{paddingLeft:'0'}}>
                        <button className="transparent full-width" onClick={cancelAddNumber}>
                        Cancel
                        </button>
                    </div>
                    <div className="col-lg-7" style={{paddingRight:'0', paddingLeft:'0'}}>
                        <button className="form-continue full-width" disabled={countcheck} onClick={validateBvn}>
                            {
                                submit ? <IsLoading />
                                :
                                'Continue'
                            }
                        </button>
                    </div>
                </div>
        </div>
    )
}

export default BvnPhoneInput
