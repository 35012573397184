import React, {useContext} from 'react';
import {ReactComponent as Left} from '../../../assets/icon/left.svg';
import { AgentCreationContext } from '../AgentCreation';
import {ReactComponent as Error} from '../../../assets/icon/error.svg';
import {ReactComponent as Check} from '../../../assets/icon/success.svg';
import IsLoading from '../../../Components/isLoading/isLoading';


const VerifyCode = () => {
    const {onClickNext, onClickPrev, onChange, validateBvn, state:{smsOtp, codeVerified, otpSuccess, activePhoneNumber, submit, otpError}} = useContext(AgentCreationContext);
    const phoneFirst = activePhoneNumber ? activePhoneNumber.substring(0,4) : '****';
    const phoneLast = activePhoneNumber ? activePhoneNumber.substring(7,11) : '****';
    const number  = (`${phoneFirst}***${phoneLast}`)
    return (
        <div>
            <div>
                <h3 className="agent-sign">Sign Up as Agent</h3>
            </div>

            <div className="sub-head">
                <h4 className="agent-verify">Verification Code</h4>
            </div>
            <div className="info-div">
                <p className="agent-info" style={{fontSize:'16px'}}>Please type the <strong>OTP</strong> sent to your phone number {number}</p>
            </div>

            <div>
                <form>
                    <div className="form-group">
                        <div className="input-group">
                            <input
                            type="password" 
                            required
                            className="form-control" 
                            placeholder="*****"
                            onChange={onChange}
                            name="smsOtp"
                            value={smsOtp}
                            minLength="4"
                            maxLength="4"
                            />
                            {
                                otpError ?
                                    <Error className="profile-password-icon" />
                                :
                                otpSuccess ?
                                    <Check className="profile-password-icon" />
                                :
                                null
                            } 
                        </div>
                    </div>

                    <div>
                        <p className="resend">Didn’t recieve an OTP?<span style={{color:'#ff0014'}} className="cursor-pointer" onClick={validateBvn}><strong> Resend OTP</strong></span></p>
                    </div>

                    {/* <div>
                        <p className="resend" style={{color:'#ff0014'}}><strong>OTP has been re-sent to the phone number above</strong></p>
                    </div> */}

                    <div style={{marginTop:'250px'}}>
                        <div className="row" style={{marginTop: '30px'}}>
                            <div className="col-lg-4" style={{paddingLeft:'0'}}>
                                <button className="form-back full-width"  onClick={onClickPrev}>
                                <Left className="right-five" />  back
                                </button>
                            </div>
                            <div className="col-lg-8" style={{paddingRight:'0', paddingLeft:'0'}}>
                                <button className="form-continue full-width" disabled={codeVerified}  onClick={onClickNext}>
                                    {
                                        submit ? <IsLoading />
                                        :
                                        'Continue'
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
    )
}

export default VerifyCode
